import AsyncHandler from './AsyncHandler.vue';
import AsyncError from './AsyncError.vue';
import { Plugin } from 'vue';

export const asyncHandler: Plugin = {
  install(app) {
    app.component('AsyncHandler', AsyncHandler);
    app.component('AsyncError', AsyncError);
  },
};

export * from './useAsyncFunc';
export * from './useAsyncPage';
export * from './useAsyncTable';
export { AsyncError };
