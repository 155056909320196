<template>
  <span text-center p-1 px-4 rounded color-light-50 break-keep :class="greyColor" inline-block>
    <slot />
  </span>
</template>

<script lang="ts" setup>
import { computed, useAttrs } from 'vue';

const attrs = useAttrs();
const greyColor = computed(() => {
  const keys = Object.keys(attrs);
  return keys.filter((key) => key.startsWith('bg-')).length > 0
    ? 'base-tag'
    : 'base-tag bg-gray-500';
});
</script>

<style>
.base-tag {
  white-space: nowrap;
}
</style>
