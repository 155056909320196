import { BlankLayout } from '@shared/naive-ui';
import { RouteRecordRaw } from 'vue-router';

export const debugRoutes = <RouteRecordRaw[]>[
  {
    name: 'debug',
    path: '/debug',
    component: () => import('./pages/DebugPage.vue'),
    meta: {
      layout: BlankLayout,
    },
  },
];
