import { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    name: 'stores',
    path: 'stores',
    component: () => import('./pages/StoresPage.vue'),
  },
  {
    name: 'stores.emplacements',
    path: 'stores/:storeId',
    component: () => import('./pages/StoreEmplacementsPage.vue'),
  },
];
