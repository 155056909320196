<template>
  <BootstrapApp>
    <UiSettings>
      <NaiveUiSetup>
        <LayoutManager :default="SiderLayout">
          <template #sider>
            <SidebarMenu v-if="mission" />
          </template>

          <div pb-18>
            <RouterView />
          </div>
        </LayoutManager>
        <HelpButton path="help/gmao_operations-user_guidelines.pdf" offline-mode />
        <ReloadPrompt />
      </NaiveUiSetup>
      <CommandsPalette />
      <AppVersion />
      <UserName />
    </UiSettings>
  </BootstrapApp>
</template>

<script setup lang="ts">
import { UiSettings, NaiveUiSetup, LayoutManager, SiderLayout } from '@shared/naive-ui';
import { AppVersion, HelpButton } from '@shared/utils';
import { UserName } from '@shared/logto/vue';
import BootstrapApp from './components/BootstrapApp.vue';
import { defineAsyncComponent } from 'vue';
import SidebarMenu from './features/menus/SidebarMenu.vue';
import { useMissionContext } from './infra';
import ReloadPrompt from './ReloadPrompt.vue';
const CommandsPalette = defineAsyncComponent(() => import('./components/CommandsPalette.vue'));

const { mission } = useMissionContext();
</script>

<style lang="postcss">
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

input:autofill {
  background: transparent;
}

.n-page-header__avatar {
  svg {
    width: 28px;
    height: 28px;
  }
}

a {
  color: var(--n-text-color);
  text-decoration: none;
}

.n-data-table .n-data-table-th {
  white-space: nowrap;
}
</style>
