<template>
  <n-card max-w-700px>
    <template #header> Synchronize events </template>
    <template #header-extra>
      <n-button secondary circle size="tiny" @click="emit('close')">
        <template #icon>
          <n-icon><Close /></n-icon>
        </template>
      </n-button>
    </template>

    <template v-if="!isSending">
      <n-timeline>
        <n-timeline-item v-for="event in filteredEvents" :key="event.id">
          <MutationsMergerEvent
            :event="event"
            :events="events"
            :merger="merger"
            @update:event="onUpdateEvent"
          />
          <n-divider />
        </n-timeline-item>
      </n-timeline>
      <div text-right>
        <n-button v-if="!hasConflicts" type="primary" secondary @click="submit">
          Synchronize
        </n-button>
      </div>
    </template>

    <template v-else>
      <n-spin :show="true" w-full>
        <template #description>
          <div>{{ statusMessage }}</div>
          <n-progress v-if="uploadProgress" type="line" :percentage="uploadProgress" />
        </template>
      </n-spin>
    </template>

    <n-alert v-if="error" title="Error" type="error" mt-2>
      {{ error.message || error }}
    </n-alert>
  </n-card>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useNotification, useLoadingBar } from 'naive-ui';
import {
  EventMergeRecord,
  MutationsMerger,
  getMissionId,
  cleanEventMergeRecord,
  cleanDeletedEvents,
  EventMergeConflict,
} from '@gmao/shared';
import { getEventsToMerge, getFilesToUpload, idb, syncEventFromMain } from '@gmao/sync';
import { Close } from '@vicons/carbon';
import MutationsMergerEvent from './MutationsMergerEvent.vue';
import { useAsyncFunc } from '@shared/naive-ui';
import { computedWithControl } from '@vueuse/core';
import { client, thenData } from '@operations/client';
import * as Sentry from '@sentry/vue';

const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'done'): void;
}>();

const merger = new MutationsMerger();
const resolves: Record<string, EventMergeConflict[] | undefined> = {};
const { data } = useAsyncFunc(() => getEventsToMerge(getMissionId()));

const events = computedWithControl(
  () => data.value,
  () => {
    return merger.run(
      [...(data.value || [])].map((record) => {
        return {
          ...structuredClone(record),
          conflicts: resolves[record.id],
        };
      }),
    );
  },
);

const filteredEvents = computed(() => events.value.filter((item) => item.type !== 'stock'));

function onUpdateEvent(event: EventMergeRecord) {
  resolves[event.id] = event.conflicts;
  events.trigger();
}

const hasConflicts = computed(() => {
  for (const event of events.value) {
    if (event.conflicts) {
      for (const conflict of event.conflicts) {
        if (conflict.type !== 'duplicate' && !conflict.resolve) return true;
      }
    }
  }
  return false;
});

const isSending = ref(false);
const statusMessage = ref('');
const uploadProgress = ref(0);
const error = ref();

const notif = useNotification();
const loadingBar = useLoadingBar();
async function submit() {
  error.value = null;
  isSending.value = true;
  loadingBar.start();

  const missionId = getMissionId();
  const mainEvents = merger.getMainEvents();
  const localEvents = await cleanDeletedEvents(merger.getEventsToSync());

  try {
    // Sync events from main
    await Promise.all(mainEvents.map(syncEventFromMain));

    const files = getFilesToUpload(localEvents);
    let count = 1;
    for (const file of files) {
      uploadProgress.value = 0;
      statusMessage.value = `Uploading file (${count}/${files.length})...`;

      const fileExists = await client.files.query
        .exists({ files: [file.uri] })
        .then(thenData)
        .then((res) => res[file.uri]);

      if (!fileExists) {
        count++;
        continue;
      }

      await client.files.mutation.upload(
        {
          file: (await idb.files.get(file.uri))!.blob,
          path: file.uri,
          overwrite: true,
        },
        {
          onUploadProgress(event) {
            uploadProgress.value = Math.round((event.progress || 0) * 100);
          },
        },
      );
      count++;
    }

    // Sync
    uploadProgress.value = 0;
    statusMessage.value = 'Sending events...';
    await client.operations.mutation.sync(
      {
        missionId,
        events: localEvents.map(cleanEventMergeRecord),
      },
      { notification: true },
    );

    notif.success({
      title: 'Success',
      content: 'Events synchronized',
      duration: 3000,
    });
    emit('done');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    error.value = err;
    Sentry.captureException(error, {
      data: {
        localEvents,
      },
    });
    loadingBar.error();
  } finally {
    loadingBar.finish();
    isSending.value = false;
  }
}
</script>
