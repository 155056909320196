<template>
  <div style="height: 100vh; position: relative">
    <n-layout position="absolute" class="top-bar-layout">
      <n-layout-header bordered class="main-header">
        <slot name="header" />
      </n-layout-header>
      <n-layout
        position="absolute"
        class="main-content"
        :native-scrollbar="false"
        content-style="padding: 24px;"
      >
        <div container mx-auto>
          <slot />
        </div>
      </n-layout>
    </n-layout>
  </div>
</template>

<style>
:root {
  --header-height: 48px;
}

.futop-barll-layout .main-header {
  height: var(--header-height);
}

.n-layout.n-layout--absolute-positioned.main-content {
  top: var(--header-height);
  bottom: 0;
}
</style>
