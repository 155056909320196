/* eslint-disable no-case-declarations */
import {
  CounterLog,
  MutationEventListResult,
  MachineStatusLog,
  MaintenanceLog,
  MoveModuleLog,
  OperationMachine,
  OperationStore,
  ResetOverdueLog,
  StockLog,
} from '@gmao/types';
import { basename } from 'pathe';
import {
  kv,
  idb,
  needSync,
  missionConfig,
  OperationFile,
  cleanMachine,
  cleanStore,
  MissionConfig,
  cleanMissionsTasks,
} from '@gmao/sync';
import { client, thenData, useAxios } from '@operations/client';
import { isAfter } from 'date-fns';
import { reactive, ref, Ref } from 'vue';
import { useMissionContext } from '@operations/infra';
import { ModalProvider, useModal } from '@shared/naive-ui';
import MutationsMergerModal from './components/MutationsMergerModal.vue';
import { missionContextState } from '@gmao/shared';
import { AssetRevealerMachineDoc, buildDocsDownloadList } from './nom';
import * as Sentry from '@sentry/vue';

const wait = (time = 500) => new Promise((resolve) => setTimeout(resolve, time));

export type UpdaterStepStatus = 'planned' | 'running' | 'done' | 'error';
const DEBUG = false;

export interface UpdaterContext {
  missionId: string;
  steps: Ref<UpdaterStepState[]>;
  modal: ModalProvider;
  files: {
    path: string;
    type: string;
    updatedAt?: string;
  }[];
  config: MissionConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

export interface UpdaterStep {
  id: string;
  name: string;
  run(this: UpdaterStepState, context: UpdaterContext): Promise<void>;
}

export interface UpdaterStepState extends UpdaterStep {
  status: {
    state: UpdaterStepStatus;
    message?: string;
    progress?: number;
    error?: string;
  };
}

export function createUpdater(missionId: string) {
  const modal = useModal();
  const { setHasUpdates } = useMissionContext();

  const status = reactive<{
    hasUpdates: boolean;
    isLoading: boolean;
    isReady: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: Error | any;
  }>({
    hasUpdates: false,
    isLoading: false,
    isReady: false,
    error: undefined,
  });

  const steps = ref<UpdaterStepState[]>([]);

  function refresh() {
    buildSteps(missionId)
      .then((resultSteps) => {
        if (resultSteps.length) status.hasUpdates = true;
        steps.value = resultSteps.map(
          (step) =>
            <UpdaterStepState>{
              ...step,
              status: {
                state: 'planned',
              },
            },
        );
      })
      .catch((err) => (status.error = err))
      .finally(() => (status.isLoading = false));
  }

  async function run() {
    // Load mission config
    const config = await kv.get<MissionConfig>(`missionConfig::${missionId}`);

    const context: UpdaterContext = {
      missionId,
      steps,
      files: [],
      modal,
      config: config!,
    };

    status.error = undefined;
    status.isReady = false;
    status.isLoading = true;

    // TODO: check missing files of already downloaded stuff

    for (const step of steps.value) {
      step.status.state = 'running';
      try {
        await step.run.call(step, context);
        step.status.state = 'done';
        await wait(500);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        step.status.error = (error.message || error) as string;
        step.status.state = 'error';
        status.error = error;

        Sentry.captureException(error);
        break;
      }

      if (context.files.length) {
        upsertStep(context.steps, buildFilesDonwloadStep());
      }
    }
    status.isLoading = false;
    if (!status.error) {
      status.isReady = true;
      status.hasUpdates = false;
      setHasUpdates(false);

      await missionConfig.set(missionId, 'fullDownloadAt', new Date().toISOString());
    }
  }

  status.isLoading = true;
  refresh();

  return { status, steps, run, refresh };
}

export async function checkUpdates(missionId: string) {
  return {
    structure: await structureHasUpdates(missionId),
    nom: await nomHasUpdates(missionId),
  };
}

async function structureHasUpdates(missionId: string) {
  const lastUpdate = await missionConfig.get(missionId, 'gmaoUpdatedAt');
  if (!lastUpdate) return true;
  const { data: dates } = await client.operations.mutation.structureUpdatesDates({
    id: missionId,
    tasksIds: await missionTasksIds(missionId),
  });

  for (const key in dates) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (isAfter(new Date((dates as any)[key] as string), new Date(lastUpdate))) {
      return true;
    }
  }
  return false;
}

async function nomHasUpdates(missionId: string) {
  const lastUpdate = await missionConfig.get(missionId, 'nomUpdatedAt');
  if (!lastUpdate) return true;
  const mission = await idb.missions.get(missionId);
  for (const machine of mission!.machines) {
    const { data } = await client.assetData.query.updates({
      id: machine.id,
      updatedAt: new Date(lastUpdate),
    });
    if (data) return true;
  }
  return false;
}

function buildStructureStep(
  options: {
    gmao?: boolean;
    nom?: boolean;
  } = { gmao: true, nom: true },
): UpdaterStep {
  return {
    id: 'structure',
    name: 'Structure',
    async run(ctx) {
      const { missionId } = ctx;
      const now = new Date().toISOString();

      if (options.gmao) {
        this.status.message = `Downloading GMAO structures...`;
        const { mission, machines, stores, tasks, deletedTasks, spls } =
          await client.operations.mutation
            .structure({ id: missionId, tasksIds: await missionTasksIds(missionId) })
            .then(thenData);

        const { added: addedStores, removed: removedStores } = await storesUpdates(
          mission.id,
          stores,
        );
        const { added: addedMachines, removed: removedMachines } = await machinesUpdates(
          mission.id,
          machines,
        );

        if (addedMachines.length) {
          options.nom = true;
        }
        if (addedStores.length || addedMachines.length) {
          upsertStep(ctx.steps, buildDataStep(), 'structure');
          upsertStep(ctx.steps, buildHistoryStep(), 'data');
        }
        if (removedStores.length) {
          await Promise.all(removedStores.map((id) => cleanStore(id, mission.id)));
        }
        if (removedMachines.length) {
          await Promise.all(removedMachines.map((id) => cleanMachine(id, mission.id)));
        }

        await idb.missions.put(mission);

        // For isLocked update
        if (missionContextState.mission?.id === mission.id) missionContextState.mission = mission;

        // Get tasks files
        for (const task of tasks) {
          if (task.filePath) {
            ctx.files.push({
              path: task.filePath,
              type: 'gmao',
            });
          }
        }

        if (deletedTasks.length) await idb.tasks.bulkDelete(deletedTasks);
        await cleanMissionsTasks(missionId);
        await idb.tasks.bulkPut(tasks);

        await idb.machines.bulkPut(machines);
        await idb.stores.bulkPut(stores);
        await idb.spls.bulkPut(spls);
        if (!DEBUG) await missionConfig.set(missionId, 'gmaoUpdatedAt', now);
      }

      if (options.nom) {
        this.status.message = `Downloading nomenclatures structures...`;

        const mission = await idb.missions.get(missionId);
        const { data: taxonomies } = await client.assetData.query.taxonomies();
        await kv.set('taxonomies', taxonomies);

        let count = 0;
        for (const machine of mission!.machines) {
          this.status.message = `Downloading nomenclatures structures: ${machine.name}...`;

          const { data } = await client.assetData.query.data({ id: machine.id });

          // Get documents to dl
          if (ctx.config.machinesIds?.includes(machine.id)) {
            const { downloads } = await buildDocsDownloadList(
              machine.id,
              data,
              (await idb.nomenclatures.get(machine.id))! || {
                ready: false,
                taxonomies,
                amos: [],
                certificates: [],
                techDocs: [],
                model: undefined,
              },
            );

            ctx.files.push(
              ...downloads.map((item: AssetRevealerMachineDoc) => ({
                type: 'nom',
                path: item.path,
                updatedAt: item.updatedAt,
              })),
            );

            // Get model files
            if (data.model && ctx.config.with3dModels) {
              const pathPrefix = `models/${data.model.name}/`;
              const modelFiles = await client.assetData.query
                .modelFiles({
                  modelName: data.model.name,
                })
                .then(thenData);

              const currentData = (await idb.models.get(data.model.name)) || {
                name: data.model.name,
                files: [],
              };

              for (const file of modelFiles) {
                const updatedAt = file.updatedAt as unknown as string;
                const filePath = `${pathPrefix}${file.path}`;

                if (currentData) {
                  const storedFile = currentData.files.find((item) => item.path === filePath);
                  if (storedFile && updatedAt === storedFile.updatedAt) {
                    continue;
                  }
                }

                ctx.files.push({
                  type: 'model',
                  path: filePath,
                  updatedAt: file.updatedAt as unknown as string,
                });
              }
            }
          }

          await idb.nomenclatures.put({ ...data, id: machine.id });
          count++;
          this.status.progress = count / mission!.machines.length;
          await wait(300);
        }
        if (!DEBUG) await missionConfig.set(missionId, 'nomUpdatedAt', now);
      }
      this.status.message = 'Done';
    },
  };
}

function buildDataStep(): UpdaterStep {
  return {
    id: 'data',
    name: 'Modules & stocks',
    async run(ctx) {
      const { missionId } = ctx;

      this.status.message = `Downloading...`;
      const { modules, stocks } = await client.operations.query
        .data({ id: missionId })
        .then(thenData);

      await idb.modules.bulkPut(modules);
      await idb.stocks.bulkPut(stocks);

      await missionConfig.set(missionId, 'dataUpdatedAt', new Date().toISOString());

      this.status.message = 'Done';
    },
  };
}

function buildHistoryStep(): UpdaterStep {
  return {
    id: 'history',
    name: 'Events history',
    async run(ctx) {
      const now = new Date().toISOString();
      const { missionId } = ctx;

      this.status.message = `Downloading...`;
      const items = await fetchFullHistory(missionId, ctx.config.fromDate, (progress) => {
        this.status.progress = progress;
      });

      await idb.mutationsEvents.bulkPut(
        items.map((item) => {
          const record = { ...item, log: undefined };
          delete record.log;
          return record;
        }),
      );

      const maintenancesLogs = items
        .filter((item) => item.type === 'maintenance')
        .map((item) => item.log) as MaintenanceLog[];
      await idb.maintenances.bulkPut(maintenancesLogs);

      const files = [
        ...maintenancesLogs.map((log) => log.pictures),
        ...maintenancesLogs.map((log) => log.documents),
      ]
        .flat()
        .map((file) => ({ path: file.uri, type: 'gmao' }));

      if (!ctx.files) ctx.files = [];
      ctx.files.push(...files);

      await idb.stocksLogs.bulkPut(
        items.filter((item) => item.type === 'stock').map((item) => item.log) as StockLog[],
      );

      await idb.moves.bulkPut(
        items.filter((item) => item.type === 'move').map((item) => item.log) as MoveModuleLog[],
      );

      await idb.countersLogs.bulkPut(
        items.filter((item) => item.type === 'counter').map((item) => item.log) as CounterLog[],
      );

      await idb.resetOverdueLogs.bulkPut(
        items
          .filter((item) => item.type === 'resetOverdue')
          .map((item) => item.log) as ResetOverdueLog[],
      );

      await idb.machineStatusLogs.bulkPut(
        items
          .filter((item) => item.type === 'machineStatus')
          .map((item) => item.log) as MachineStatusLog[],
      );

      if (!DEBUG) await missionConfig.set(missionId, 'syncAt', now);
      this.status.message = 'Done';
    },
  };
}

function buildFilesDonwloadStep(): UpdaterStep {
  return {
    id: 'files',
    name: 'Files',
    async run(ctx) {
      if (!ctx.files?.length) {
        this.status.message = 'No file to download';
        return;
      }

      let count = 1;
      for (const infos of ctx.files) {
        this.status.message = `Downloading file ${basename(infos.path)} (${count}/${
          ctx.files.length
        })...`;

        try {
          this.status.progress = 0;
          if (await localFile(infos.type, infos.path)) {
            continue;
          }

          const file = await fileGet(infos.path, (progress) => {
            this.status.progress = progress;
          });

          switch (infos.type) {
            case 'gmao':
              await idb.files.put({
                ...file,
              });
              break;
            case 'nom':
              await idb.nomenclaturesDocs.put({
                file: file.blob,
                id: Number.parseInt(/^documents\/([0-9]+).pdf$/.exec(infos.path)![1]),
                updatedAt: infos.updatedAt!,
              });
              break;
            case 'model':
              const modelName = /^models\/([^/]+)\//.exec(infos.path)![1];
              const existingModel = await idb.models.get(modelName);
              const modelFiles = existingModel?.files || [];
              const modelFile = {
                path: infos.path,
                data: file.blob,
                updatedAt: infos.updatedAt!,
              };

              const fileIndex = modelFiles.findIndex((item) => item.path === modelFile.path);
              if (fileIndex >= 0) {
                modelFiles.splice(fileIndex, 1, modelFile);
              } else {
                modelFiles.push(modelFile);
              }

              await idb.models.put({
                name: modelName,
                files: modelFiles,
              });
              break;
          }
        } catch {
          // Silently fail
        }

        count++;
      }

      await missionConfig.set(ctx.missionId, 'filesUpdatedAt', new Date().toISOString());
    },
  };
}

function buildEventsMergerStep(): UpdaterStep {
  return {
    id: 'events',
    name: 'Events',
    async run(ctx) {
      const { event } = await ctx.modal.open({
        component: MutationsMergerModal,
        closeEvents: ['close', 'done'],
        props: {
          missionId: ctx.missionId,
        },
      });

      if (event !== 'done') {
        throw new Error(`You need to sync events to update your local data`);
      }
    },
  };
}

async function buildSteps(missionId: string) {
  const { structure, nom } = await checkUpdates(missionId);

  const steps: UpdaterStep[] = [];

  const lastEventsSync = await missionConfig.get(missionId, 'syncAt');
  const hasEventsUpdates = await needSync(missionId);

  if (lastEventsSync && hasEventsUpdates) {
    steps.push(buildEventsMergerStep());
  }

  if (structure || nom)
    steps.push(
      buildStructureStep({
        gmao: structure,
        nom: nom,
      }),
    );

  if (!(await missionConfig.get(missionId, 'gmaoUpdatedAt'))) steps.push(buildDataStep());
  if (!(await missionConfig.get(missionId, 'syncAt'))) steps.push(buildHistoryStep());

  return steps;
}

async function fetchFullHistory(
  missionId: string,
  fromDate?: string,
  progressCallback?: (value: number) => unknown,
): Promise<MutationEventListResult['items']> {
  let progress = 0;
  let end = false;
  let total = -1;
  let page = 1;
  const perPage = 500;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const items: any[] = [];
  while (!end) {
    const { data } = await client.operations.query.eventsList({
      missionId,
      page,
      perPage,
      full: true,
      fromDate,
    });

    total = data.total;
    items.push(...data.items);

    if (items.length >= total || !data.items.length) {
      end = true;
    }
    page++;

    const maxPage = Math.ceil(total / perPage);
    progress = (page - 1) / maxPage;
    progressCallback?.(progress);
    await wait(500);
  }
  return items;
}

function upsertStep(steps: Ref<UpdaterStepState[]>, step: UpdaterStep, after?: string) {
  const index = steps.value.findIndex((item) => item.id === step.id);
  const afterIndex = after ? steps.value.findIndex((item) => item.id === after) : -1;

  const stepWithState: UpdaterStepState = {
    ...step,
    status: {
      state: 'planned',
    },
  };

  if (index >= 0) {
    steps.value[index] = stepWithState;
    return;
  } else if (afterIndex > 0) {
    steps.value.splice(afterIndex, 0, stepWithState);
    return;
  }
  steps.value.push(stepWithState);
}

async function storesUpdates(missionId: string, newItems: OperationStore[]) {
  const mission = await idb.missions.get(missionId);

  const currentIds = mission?.stores.map((item) => item.id) || [];
  const newIds = newItems.map((item) => item.id);

  const added = newIds.filter((id) => !currentIds.includes(id));
  const removed = currentIds.filter((id) => !newIds.includes(id));

  return { added, removed };
}

async function machinesUpdates(missionId: string, newItems: OperationMachine[]) {
  const mission = await idb.missions.get(missionId);

  const currentIds = mission?.machines.map((item) => item.id) || [];
  const newIds = newItems.map((item) => item.id);

  const added = newIds.filter((id) => !currentIds.includes(id));
  const removed = currentIds.filter((id) => !newIds.includes(id));

  return { added, removed };
}

async function localFile(type: string, path: string): Promise<OperationFile | undefined> {
  switch (type) {
    case 'gmao':
      return idb.files.get(path);
    case 'nom':
      const doc = await idb.nomenclaturesDocs.get(
        Number.parseInt(/^documents\/([0-9]+).pdf$/.exec(path)![1]),
      );
      return doc ? { path, blob: doc.file, createdAt: doc.updatedAt } : undefined;
    case 'model':
      const modelName = /^models\/([^/]+)\//.exec(path)![1];
      const model = await idb.models.get(modelName);
      if (model) {
        const file = model.files.find((item) => item.path === path);
        return file ? { path, blob: file.data, createdAt: file.updatedAt } : undefined;
      }
  }
}

async function fileGet(
  path: string,
  progress?: ((progres: number) => unknown) | undefined,
): Promise<OperationFile> {
  const fileUrl = `/api/files/get/${path}`;

  const axios = useAxios();
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    onDownloadProgress(event) {
      if (progress) progress(event.progress || 0);
    },
  });

  return {
    path,
    blob,
    createdAt: new Date().toISOString(),
  };
}

async function missionTasksIds(missionId: string) {
  const mission = await idb.missions.get(missionId);
  if (!mission) return [];

  const machinesIds = mission.machines.map((item) => item.id);
  const allMachines = await idb.machines.toArray();

  const missionsAmosIds = allMachines
    .filter((item) => machinesIds.includes(item.id))
    .map((item) => item.funcLocations.map((fl) => fl.amosId))
    .flat();

  const missionTasks = (await idb.tasks.toArray()).filter((item) => {
    for (const amosId of missionsAmosIds) {
      if (item.amosIds.includes(amosId)) return true;
    }
    return false;
  });

  return missionTasks.map((item) => item.id);
}
