/* eslint-disable @typescript-eslint/ban-ts-comment */
import { computed, ComputedRef, defineComponent, h, provide, reactive, toRaw, unref } from 'vue';
import { usePreferredColorScheme } from '@vueuse/core';
import { createStorage } from 'unstorage';
// @ts-ignore
import localStorageDriver from 'unstorage/drivers/localstorage';

export interface UiSettingsData {
  siderCollapsed: boolean;
  darkMode: boolean;
  features: Features;
}

interface Features {
  altSidebarMenu?: boolean;
}

export interface UiSettingsProvider {
  loaded: ComputedRef<boolean>;

  setSiderCollapsed(value: boolean): Promise<void>;
  siderCollapsed: ComputedRef<boolean>;

  toggleDarkMode(): Promise<void>;
  darkMode: ComputedRef<boolean>;

  toggleFeature(name: keyof Features): Promise<void>;
  features: ComputedRef<Features>;
}

export const UiSettings = defineComponent({
  setup(_, { slots }) {
    const storage = createStorage({ driver: localStorageDriver({ base: 'uiSettings' }) });

    const settings = reactive({
      loaded: false,
      siderCollapsed: false,
      darkMode: unref(usePreferredColorScheme()) === 'dark',
      features: <Features>{
        altSidebarMenu: false,
      },
    });

    storage.getItem('values').then((values) => {
      if (values) {
        const { siderCollapsed, darkMode, features } = values as UiSettingsData;
        settings.siderCollapsed = siderCollapsed || false;
        settings.darkMode =
          darkMode !== undefined ? darkMode : unref(usePreferredColorScheme()) === 'dark';
        settings.features = features || {};
      }
      settings.loaded = true;
    });

    const provider: UiSettingsProvider = {
      loaded: computed(() => settings.loaded),

      async setSiderCollapsed(value: boolean) {
        settings.siderCollapsed = value;
        return storage.setItem('values', toRaw(settings));
      },
      siderCollapsed: computed(() => settings.siderCollapsed),

      async toggleDarkMode() {
        settings.darkMode = !settings.darkMode;
        return storage.setItem('values', toRaw(settings));
      },
      darkMode: computed(() => settings.darkMode),

      async toggleFeature(name: keyof Features) {
        settings.features[name] = !settings.features[name];
        return storage.setItem('values', toRaw(settings));
      },
      features: computed(() => settings.features),
    };

    provide('uiSettings', provider);

    return () => h('div', null, settings.loaded ? slots : []);
  },
});
