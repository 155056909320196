import { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    name: 'dashboard',
    path: '',
    component: () => import('./pages/DashboardPage.vue'),
    children: [
      {
        path: '',
        name: 'dashboard.maintenancePlan',
        component: () => import('./pages/MaintenancePlanPage.vue'),
      },
      {
        path: 'corrective',
        name: 'dashboard.correctiveTasks',
        component: () => import('./pages/CorrectiveTasksPage.vue'),
      },
    ],
  },
];
