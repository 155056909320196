<template>
  <div
    v-if="userInfos?.sub"
    px-2
    py-1
    bg-gray-800
    text-light-500
    fixed
    bottom-10px
    right-20px
    rounded-lg
    flex
    items-center
    z-1
  >
    <n-icon mr-1>
      <User />
    </n-icon>
    {{
      userInfos
        ? userInfos.name || userInfos.username || userInfos.email || 'Unknown user'
        : 'Unknown user'
    }}
  </div>
</template>

<script lang="ts" setup>
import { useAuth } from '../plugin';
import { User } from '@vicons/carbon';

const { userInfos } = useAuth();
</script>
