<template>
  <n-alert :type="type" :title="message" :show-icon="false">
    <template v-if="resolveType" #icon>
      <n-icon><Checkmark /></n-icon>
    </template>
    <template #header>
      <div flex items-center gap-2>
        <span flex-grow min-w-200px>{{ message }}</span>
        <n-select
          v-model:value="resolveType"
          flex-grow
          placeholder="Please select resolution"
          :options="options"
          clearable
        />
      </div>
    </template>
  </n-alert>
</template>

<script lang="ts" setup>
import { Checkmark } from '@vicons/carbon';
import {
  EventMergeRecord,
  MutationsMerger,
  getConflictResolve,
  getMergeConflictResolvesOptions,
  mergeConflictMessages,
} from '@gmao/shared';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  event: EventMergeRecord;
  merger: MutationsMerger;
}>();

const emit = defineEmits<{
  (event: 'update:event', payload: EventMergeRecord): unknown;
}>();

const resolveType = ref<string | null>(props.event.conflicts![0].resolve?.type || null);

const type = computed(() =>
  resolveType.value
    ? 'default'
    : props.event.conflicts![0].type === 'duplicate'
      ? 'warning'
      : 'error',
);
const message = computed(() => mergeConflictMessages[props.event.conflicts![0].type!]);
const options = computed(() =>
  getMergeConflictResolvesOptions(props.event, props.event.conflicts![0]),
);

watch(resolveType, async (value) => {
  const clone = structuredClone(props.event);

  value
    ? (clone.conflicts![0].resolve = await getConflictResolve(value, props.event, props.merger))
    : (clone.conflicts![0].resolve = undefined);

  emit('update:event', clone);
});
</script>
