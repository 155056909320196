<template>
  <div>
    <ModuleSerialNumber :serial-number="log.moduleSerialNumber" />
    <div mt-2 flex flex-col gap-2>
      From
      <SystemLocation
        :machine-name="log.from.machineName"
        :func-location="log.from.funcLocationName"
        :store-name="log.from.storeName"
        :emplacement="log.from.emplacementName"
      />
      To
      <SystemLocation
        :machine-name="log.to.machineName"
        :func-location="log.to.funcLocationName"
        :store-name="log.to.storeName"
        :emplacement="log.to.emplacementName"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { MoveModuleLog } from '@gmao/types';
import { EventMergeRecord, ModuleSerialNumber, SystemLocation } from '@gmao/shared';

const props = defineProps<{
  event: EventMergeRecord;
  events: EventMergeRecord[];
}>();

const log = computed(() => props.event.log as MoveModuleLog);
</script>
