import { camelize, Component, computed, ComputedRef, inject, markRaw, provide, ref } from 'vue';
import { nanoid } from 'nanoid';
import { ModalProps } from 'naive-ui';

export interface ModalOptions {
  component: Component;
  closeEvents?: string[];
  props?: Record<string, unknown>;
  modalProps?: ModalProps;
}

export interface ModalInstance extends Omit<ModalOptions, 'closeEvents'> {
  id: string;
  modalProps: Record<string, unknown>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ModalValue<T = any> {
  event: string;
  data: T;
}

export interface ModalProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open: <T = any>(options: ModalOptions) => Promise<ModalValue<T>>;
}

const modalManagerToken = Symbol.for('modalManager');

export function createModalManager(): {
  modals: ComputedRef<ModalInstance[]>;
} {
  const modals = ref<ModalInstance[]>([]);

  function open({ component, props, modalProps, closeEvents }: ModalOptions) {
    if (!modalProps)
      modalProps = {
        closeOnEsc: true,
        maskClosable: true,
      };

    return new Promise((resolve) => {
      const instance: ModalInstance = {
        id: nanoid(),
        component: markRaw(component),
        props: props || {},
        modalProps: modalProps as Record<string, unknown>,
      };

      for (const eventName of closeEvents || ['close']) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        instance.props![camelize(`on-${eventName}`)] = (data: unknown) => {
          resolve({ event: eventName, data });
          close(instance);
        };
      }

      instance.modalProps.onEsc = (event: unknown) => {
        if (!modalProps?.closeOnEsc) return;
        resolve({ event: 'nativeClose', data: event });
        close(instance);
      };

      instance.modalProps.onMaskClick = (event: unknown) => {
        if (!modalProps?.maskClosable) return;
        resolve({ event: 'nativeClose', data: event });
        close(instance);
      };

      modals.value.push(instance);
    });
  }

  function close(instance: ModalInstance) {
    const index = modals.value.findIndex((item) => item.id === instance.id);
    if (index >= 0) {
      modals.value.splice(index, 1);
    }
  }

  provide(modalManagerToken, <ModalProvider>{
    open,
  });

  return {
    modals: computed(() => modals.value),
  };
}

export function useModal() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject<ModalProvider>(modalManagerToken)!;
}
