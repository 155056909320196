import 'uno.css';
import '@unocss/reset/normalize.css';
import 'vfonts/Lato.css';
import 'vfonts/FiraCode.css';
import '@gmao/shared/styles.css';
import './styles.css';

import { createApp } from 'vue';
import { router } from './router';
import App from './App.vue';
import { asyncHandler, debugPlugin, onlineChecker } from '@shared/naive-ui';
import { client } from './client';
import { authPlugin } from '@shared/logto/vue';
import { authConfig } from './config/auth';
import { missionContext } from './infra';
import { socketIo } from '@gmao/sync';
import { cartPlugin } from '@gmao/shared';
import { setupSentry } from './sentry';

export const app = createApp(App);

setupSentry(app);

// Setup
app
  .use(router)
  .use(debugPlugin)
  .use(asyncHandler)
  .use(onlineChecker, { socketIo })
  .use(authPlugin, { config: authConfig, client })
  .use(missionContext, { router })
  .use(cartPlugin);

app.mount('#app');
