<template>
  <div>
    <div flex items-center gap-1>
      <SystemLocation
        :machine-name="log.machineName"
        :func-location="log.funcLocationName"
        :store-name="log.storeName"
        :emplacement="log.emplacementName"
      />
      <ModuleSerialNumber v-if="log.moduleSerialNumber" :serial-number="log.moduleSerialNumber" />
    </div>
    <div font-bold mt-2>
      {{ log.taskId ? 'Planned task: ' : 'Unplanned task' }}
      {{ log.taskName }}
    </div>
    <div v-if="log.comment" mb-2>{{ log.comment }}</div>
    <EventMaintenanceStocks v-if="stocksLogs" :stocks-logs="stocksLogs" mt-2 />

    <div v-if="full">
      <div flex flex-wrap gap-1 mb-2>
        <LabelName v-for="term in log.types" :key="term.id" :name="term.name" inline-block />
      </div>
      <div v-if="log.documents && log.documents.length" mb-2>
        <div>
          <div v-for="item in log.documents" :key="item.uri">
            <DocumentLink :uri="item.uri" />
          </div>
        </div>
      </div>
      <div v-if="log.pictures && log.pictures.length">
        <div grid md:grid-cols-3 gap-2>
          <ImagePreview
            v-for="item in log.pictures"
            :key="item.uri"
            :uri="item.uri"
            max-w-full
            cover
            rounded
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { MutationEventWithLog, MaintenanceLog, StockLog } from '@gmao/types';
import { EventMergeRecord, LabelName, ModuleSerialNumber, SystemLocation } from '@gmao/shared';
import EventMaintenanceStocks from './EventMaintenanceStocks.vue';
import { DocumentLink, ImagePreview } from '../files';

const props = defineProps<{
  event: MutationEventWithLog;
  events?: EventMergeRecord[];
  full?: boolean;
}>();

const log = computed(() => props.event.log as MaintenanceLog);
const stocksLogs = computed(
  () =>
    props.events
      ?.filter((item) => (item.log as StockLog)?.maintenanceLogEventId === props.event.id)
      .map((item) => item.log as StockLog),
);
</script>
