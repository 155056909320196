import { useAxios } from '@shared/client';
import { useNotification } from 'naive-ui';

export interface PdfViewerOptions {
  zoom?: 'page-width' | 'auto';
}

export function getPdfViewerUrl(
  pdfUrl: string,
  title = '',
  options: PdfViewerOptions = {},
): string {
  const query: Record<string, string> = {
    file: pdfUrl,
  };

  const hashOptions: Record<string, string> = {
    zoom: options.zoom || 'auto',
    pagemode: 'none',
  };

  if (title) query.name = title;

  const queryParams = Object.keys(query)
    .map((key) => `${key}=${encodeURIComponent(query[key])}`)
    .join('&');

  const hashParams = Object.keys(hashOptions)
    .map((key) => `${key}=${encodeURIComponent(hashOptions[key])}`)
    .join('&');

  return `/pdfjs/web/viewer.html?${queryParams}#${hashParams}`;
}

type ProgressCallback = (progres: number) => unknown | void;

export function useDocumentLoader() {
  const notification = useNotification();
  const axios = useAxios();

  return async (
    idOrUri: number | string,
    progress?: ProgressCallback,
    notifications = true,
  ): Promise<Blob> => {
    try {
      const docUrl =
        typeof idOrUri === 'number' ? `/api/files/docs/${idOrUri}` : `/api/files/get/${idOrUri}`;

      const { data } = await axios.get(docUrl, {
        responseType: 'blob',
        onDownloadProgress(event) {
          if (progress) progress(event.progress || 0);
        },
      });

      return data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (notifications) {
        if (err.response?.status === 404) {
          notification.error({
            title: 'Error',
            content: 'Document not found on file storage',
            duration: 3000,
          });
        } else {
          notification.error({
            title: 'Error',
            content: err.message || err,
            duration: 3000,
          });
        }
      }
      throw err;
    }
  };
}
