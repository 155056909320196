import { computed, ComputedRef, inject, InjectionKey, Plugin, ref } from 'vue';
import DebugDump from './DebugDump.vue';
import { createStorage } from 'unstorage';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localStorageDriver from 'unstorage/drivers/localstorage';

interface DebugProvider {
  enable(): void;
  disable(): void;
  toggle(): void;
  enabled: ComputedRef<boolean>;
}

const debugToken: InjectionKey<DebugProvider> = Symbol.for('DebugProvider');

export const debugPlugin: Plugin = {
  install(app) {
    const storage = createStorage({ driver: localStorageDriver({ base: 'debug' }) });
    const debugEnabled = ref(false);

    storage.getItem('enabled').then((value) => (debugEnabled.value = value as boolean));

    // eslint-disable-next-line vue/multi-word-component-names
    app.component('Dump', DebugDump);

    app.provide(debugToken, <DebugProvider>{
      enable() {
        debugEnabled.value = true;
      },
      disable() {
        debugEnabled.value = false;
      },
      toggle() {
        debugEnabled.value = !debugEnabled.value;
        storage.setItem('enabled', debugEnabled.value);
      },
      enabled: computed(() => debugEnabled.value),
    });
  },
};

export function useDebug(): DebugProvider {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject(debugToken)!;
}
