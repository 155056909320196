<template>
  <BaseTag
    :class="{
      [color]: true,
    }"
    v-bind="{ [color]: '' }"
    inline-flex
    items-center
  >
    <span>{{ label }}</span>
    <slot name="suffix" />
  </BaseTag>
</template>

<script lang="ts" setup>
import { BaseTag } from '@gmao/shared';
import { MutationTypes } from '@gmao/types';
import { computed } from 'vue';
import { eventTypesColors, eventTypesLabels } from './eventTypes';

const props = defineProps<{
  type: MutationTypes;
}>();

const label = computed(() => {
  return eventTypesLabels[props.type];
});

const color = computed(() => {
  return eventTypesColors[props.type];
});
</script>
