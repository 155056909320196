import { idb } from '@gmao/sync';
import { AssetDataMachineData } from '@gmao/types';

export interface AssetRevealerMachineDoc {
  id: number;
  path: string;
  updatedAt: string;
}

function getMachineDocs(data: AssetDataMachineData): AssetRevealerMachineDoc[] {
  return [
    ...data.amos
      .filter((item) => !!item.doc)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((item) => ({
        id: Number(item.doc!.id),
        updatedAt: item.doc!.updatedAt,
      })),
    ...data.techDocs.map((item) => ({ id: Number(item.id), updatedAt: item.updatedAt })),
    ...data.certificates.map((item) => ({
      id: Number(item.id),
      updatedAt: item.updatedAt,
    })),
  ].map((item) => ({
    ...item,
    path: `documents/${item.id}.pdf`,
  }));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function unusedDocsIds(ids: number[], excludeMachineId?: number): Promise<number[]> {
  // const machines = await idb.machines
  //   .where('id')
  //   .notEqual(excludeMachineId || 0)
  //   .toArray();

  // const allDocsIds = [
  //   ...new Set([
  //     ...machines
  //       .map((record) => getMachineDocs(record.data))
  //       .flat()
  //       .map((item) => item.id),
  //   ]),
  // ];

  // return ids.filter((id) => !allDocsIds.includes(id));
  return [];
}

export async function buildDocsDownloadList(
  machineId: number,
  mainData: AssetDataMachineData,
  localData: AssetDataMachineData,
): Promise<{
  downloads: AssetRevealerMachineDoc[];
  deletes: number[];
}> {
  const oldDocs = getMachineDocs(localData);
  const newDocs = getMachineDocs(mainData);

  const oldIds = oldDocs.map((item) => item.id);
  const newIds = newDocs.map((item) => item.id);

  const deletes = await unusedDocsIds(
    oldIds.filter((id) => !newIds.includes(id)),
    machineId,
  );

  const downloads: AssetRevealerMachineDoc[] = [];
  for (const doc of newDocs) {
    const docExists = await idb.nomenclaturesDocs.get(doc.id);
    if (docExists && doc.updatedAt === docExists.updatedAt) continue;
    downloads.push(doc);
  }

  return {
    downloads,
    deletes,
  };
}
