<script lang="ts" setup>
import { createModalManager } from './modalManager';

const { modals } = createModalManager();
</script>

<template>
  <slot />
  <n-modal v-for="item in modals" :key="item.id" :show="true" v-bind="item.modalProps">
    <component :is="item.component" v-bind="item.props" />
  </n-modal>
</template>
