<template>
  <n-layout-sider
    v-model:collapsed="collasped"
    bordered
    show-trigger
    collapse-mode="width"
    :collapsed-width="64"
    :width="240"
    :native-scrollbar="false"
  >
    <slot />
  </n-layout-sider>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useUiSettings } from './useUiSettings';

const { setSiderCollapsed, siderCollapsed } = useUiSettings();
const collasped = computed({
  get: () => siderCollapsed.value,
  set: (value) => setSiderCollapsed(value),
});
</script>
