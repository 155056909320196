<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue';
const { needRefresh, updateServiceWorker } = useRegisterSW();
</script>

<template>
  <n-card v-if="needRefresh" class="pwa-toast" role="alert" max-w-300px>
    <div class="message" mb-4>
      <span> New version available, click on reload button to update. </span>
    </div>
    <n-button secondary type="primary" @click="updateServiceWorker()">Reload</n-button>
  </n-card>
</template>

<style>
.pwa-toast {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  z-index: 10000;
}
</style>
