import { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    name: 'history',
    path: 'history',
    redirect: { name: 'history.latest' },
    component: () => import('./pages/HistoryPage.vue'),
    children: [
      {
        path: '',
        name: 'history.latest',
        component: () => import('./pages/HistoryLatestPage.vue'),
      },
      {
        path: 'all',
        name: 'history.all',
        component: () => import('./pages/HistoryAllPage.vue'),
      },
    ],
  },
];
