import { Files } from '../../ports/files';
import { OperationFile, idb, socketIo } from './sync';
import { files as managementFiles } from '../management/files';
import { ClientUploadProgressPayload } from '@minusjs/tuf/client';
import { cleanUploads } from './sync/clean';

class OperationsFiles implements Files {
  public async save(
    filename: string,
    blob: Blob,
    progress?: (payload: ClientUploadProgressPayload) => unknown,
  ): Promise<OperationFile> {
    const path = `uploads/gmao/${new Date().getTime()}-${filename}`;
    const file: OperationFile = {
      path,
      blob,
      createdAt: new Date().toISOString(),
    };

    await idb.files.put(file);
    if (socketIo.connected) {
      try {
        await managementFiles.save(filename, blob, progress, path);
      } catch {
        // Silent fail
      }
    }
    await cleanUploads();

    return file;
  }

  public async get(path: string): Promise<OperationFile> {
    const file = await idb.files.get(path);
    if (file) return file;
    if (socketIo.connected) {
      const file = await managementFiles.get(path);
      await idb.files.put(file);
      return file;
    }
    throw new Error(`File not found`);
  }
}

export const files = new OperationsFiles();
