import { idb, needSync, socketIo } from '@gmao/sync';
import { Plugin, watch, inject, computed, ComputedRef } from 'vue';
import { Router } from 'vue-router';
import { useIntervalFn } from '@vueuse/core';
import { MissionContext, missionContextState as ctx } from '@gmao/shared';
import { isAfter, isBefore } from 'date-fns';
import { checkUpdates } from '@operations/features/missions/updater';

interface MissionContextOptions {
  router: Router;
}

type MissionContextReadonly = Required<{
  [K in keyof MissionContext]: ComputedRef<Exclude<MissionContext[K], undefined>>;
}> & {
  isLocked: ComputedRef<boolean>;
  setMachineId(id: number): void;
  setHasUpdates(value: boolean): void;
  setHasEventsUpdates(value: boolean): void;
  checkUpdates(): Promise<void>;
};

const CKECK_UPDATE_INTERVAL = 60_000;

export const missionContext: Plugin = {
  install(app, { router }: MissionContextOptions) {
    async function _checkStructuresUpdates() {
      const { structure, nom } = await checkUpdates(ctx.mission!.id);
      if (structure || nom) {
        ctx.hasUpdates = true;
      }
    }

    async function _checkEventsUpdates() {
      ctx.hasEventsUpdates = await needSync(ctx.mission!.id);
    }

    async function checkAllUpdates() {
      if (!ctx.mission || !socketIo.connected) return;
      try {
        pauseCheckUpdates();
        await _checkStructuresUpdates();
        await _checkEventsUpdates();
      } finally {
        resumeCheckUpdates();
      }
    }

    const { pause: pauseCheckUpdates, resume: resumeCheckUpdates } = useIntervalFn(
      checkAllUpdates,
      CKECK_UPDATE_INTERVAL,
    );
    pauseCheckUpdates();

    async function getMission(id: string) {
      ctx.isLoading = true;

      try {
        ctx.mission = await idb.missions.get(id);
        resumeCheckUpdates();

        if (
          !ctx.currentMachineId ||
          !ctx.mission?.machines.find((m) => m.id === ctx.currentMachineId)
        )
          ctx.currentMachineId = ctx.mission?.machines[0].id || 0;
      } finally {
        ctx.isLoading = false;
      }
    }

    watch(
      () => router.currentRoute.value.params.missionId,
      (missionId) => {
        if (!missionId) pauseCheckUpdates();
      },
      { immediate: true },
    );

    router.beforeEach(async (to) => {
      if (to.params.missionId && to.params.missionId !== ctx.mission?.id) {
        await getMission(to.params.missionId as string);
      }
    });

    app.provide('missionContext', {
      mission: computed(() => ctx.mission!),
      currentMachineId: computed(() => ctx.currentMachineId!),
      isLoading: computed(() => ctx.isLoading),
      hasUpdates: computed(() => ctx.hasUpdates),
      setHasUpdates(value: boolean) {
        ctx.hasUpdates = value;
      },
      hasEventsUpdates: computed(() => ctx.hasEventsUpdates),
      setHasEventsUpdates(value: boolean) {
        ctx.hasEventsUpdates = value;
      },
      isLocked: computed(() => {
        if (!ctx.mission) return true;

        if (isAfter(new Date(), new Date(ctx.mission.startDate))) {
          if (!ctx.mission.endDate) return false;
          else if (ctx.mission.endDate && isBefore(new Date(), new Date(ctx.mission.endDate)))
            return false;
        }

        return true;
      }),
      setMachineId(id) {
        ctx.currentMachineId = id;
      },
      checkUpdates: checkAllUpdates,
    } satisfies MissionContextReadonly);
  },
};

export function useMissionContext(): MissionContextReadonly {
  return inject('missionContext')!;
}
