/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAsyncState, UseAsyncStateOptions } from '@vueuse/core';
import { computed, ComputedRef, Ref, watch, WatchSource } from 'vue';

type UseAsyncStateFunc<Data = any> = Promise<Data> | ((...args: any[]) => Promise<Data>);
export type AsyncFunc<Data = any> = (...args: any[]) => Promise<Data> | Data;
type MultiWatchSources = (WatchSource<unknown> | object)[];
export type UseAsyncFuncOptions<Shallow extends boolean = true> = UseAsyncStateOptions<Shallow> & {
  watch?: MultiWatchSources;
};
export interface UseAsyncFuncStatus {
  error: unknown;
  isLoading: boolean;
  isReady: boolean;
}
export interface UseAsyncFuncReturn<Data = any, Func = AsyncFunc<Data>> {
  data: Ref<Data>;
  execute: Func;
  status: ComputedRef<UseAsyncFuncStatus>;
}

export function useAsyncFunc<
  Func extends AsyncFunc,
  Data = Func extends AsyncFunc<infer T> ? T : never,
  InitialData extends Data = Data,
  Shallow extends boolean = true,
>(
  func: Func,
  initialData?: InitialData,
  options: UseAsyncFuncOptions<Shallow> = {},
): UseAsyncFuncReturn<Data | InitialData, Func> {
  const { state, error, execute, isLoading, isReady } = useAsyncState(
    func as UseAsyncStateFunc,
    initialData,
    {
      resetOnExecute: false,
      ...options,
    },
  );

  const status = computed<UseAsyncFuncStatus>(() => ({
    error: error.value,
    isLoading: isLoading.value,
    isReady: isReady.value,
  }));

  if (options.watch) {
    watch(options.watch, () => execute());
  }

  return {
    data: state,
    execute: execute as Func,
    status,
  };
}
