import { idb } from '@gmao/sync';
import { getMissionId } from '@gmao/shared';
import { ModulesListResult, OperationMission } from '@gmao/types';
import type { AssetDataAmos } from '@gmao/types';

export async function getMission(): Promise<OperationMission> {
  const mission = await idb.missions.get(getMissionId());
  if (!mission) throw new Error('Unable to find mission: ' + getMissionId());
  return mission;
}

export async function getMissionMachines() {
  const mission = await getMission();
  const ids = mission.machines.map((item) => item.id);
  const items = (await idb.machines.toArray()).filter((item) => ids.includes(item.id));
  return items;
}

export async function getMissionStores() {
  const mission = await getMission();
  const ids = mission.stores.map((item) => item.id);
  const items = (await idb.stores.toArray()).filter((item) => ids.includes(item.id));
  return items;
}

export async function getMissionSpls() {
  const mission = await getMission();
  const ids = mission.spls?.map((item) => item.id) || [];
  const items = (await idb.spls.toArray()).filter((item) => ids.includes(item.id));
  return items;
}

export async function getFuncLocation(id: string) {
  const machines = await getMissionMachines();
  for (const machine of machines) {
    const funcLocation = machine.funcLocations.find((item) => item.id === id);
    if (funcLocation) {
      const module = (await idb.modules.toArray()).filter((item) => item.funcLocationId === id)[0];
      funcLocation.moduleSerialNumber = module?.serialNumber;
      return funcLocation;
    }
  }
}

export async function getEmplacement(id: string) {
  const stores = await getMissionStores();
  for (const store of stores) {
    const emplacement = store.emplacements.find((item) => item.id === id);
    if (emplacement) return emplacement;
  }
}

export async function getMissionModules(): Promise<ModulesListResult['items']> {
  const [machines, stores] = await Promise.all([getMissionMachines(), getMissionStores()]);

  const funcLocations = machines.map((item) => item.funcLocations).flat();
  const emplacements = stores.map((item) => item.emplacements).flat();

  const flsIds = funcLocations.map((item) => item.id);
  const emplacementsIds = emplacements.map((item) => item.id);

  const items = (await idb.modules.toArray())
    .filter(
      (item) =>
        flsIds.includes(item.funcLocationId!) || emplacementsIds.includes(item.emplacementId!),
    )
    .map((item) => {
      let funcLocationInfos = {};
      let emplacementInfos = {};

      if (item.funcLocationId) {
        const fl = funcLocations.find((row) => row.id === item.funcLocationId);
        funcLocationInfos = {
          funcLocationId: item.funcLocationId,
          funcLocationName: fl?.name,
          machineId: fl?.machineId,
          machineName: machines.find((m) => m.id === fl?.machineId)?.name,
        };
      } else if (item.emplacementId) {
        const emplacement = emplacements.find((row) => row.id === item.emplacementId);
        emplacementInfos = {
          emplacementId: item.emplacementId,
          emplacementName: emplacement?.name,
          storeId: emplacement?.storeId,
          storeName: stores.find((s) => s.id === emplacement?.storeId)?.name,
        };
      }

      return {
        ...item,
        ...funcLocationInfos,
        ...emplacementInfos,
      };
    });

  return items;
}

export async function getMissionAmos(machinesIds?: number[]) {
  const machines = await getMissionMachines();

  const amos = (
    await Promise.all(
      machines
        .filter((machine) => (machinesIds?.length ? machinesIds.includes(machine.id) : true))
        .map(async (machine) => {
          return (await idb.nomenclatures.get(machine.id))?.amos || [];
        }),
    )
  )
    .flat()
    .reduce((items: AssetDataAmos[], item) => {
      if (items.findIndex((row) => row.id === item.id) < 0) items.push(item);
      return items;
    }, []);

  return amos;
}

export function normalizeString(input?: string): string {
  return (input || '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

export function fieldSorter<T = string | number>(a: T, b: T): number {
  return typeof a === 'string' ? a.localeCompare(b as string) : (a as number) - (b as number);
}

export function compileObjectTexts<T extends object>(data: T, fields: (keyof T)[]) {
  return normalizeString(fields.map((field) => (data[field] || '').toString()).join(' '));
}

export function searchTextObject<T extends object>(data: T, fields: (keyof T)[], search: string) {
  return compileObjectTexts(data, fields).includes(normalizeString(search));
}
