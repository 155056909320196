/* eslint-disable no-case-declarations */
import { basename } from 'pathe';
import { client, thenData } from '../../management/api';
import { socketIo } from './socket-io';
import { idb, missionConfig } from './idb';
import { getMissionId, missionContextState } from '../../../features';
import {
  CounterLog,
  MachineStatusLog,
  MaintenanceLog,
  MoveModuleLog,
  MutationEvent,
  MutationEventMaintenance,
  MutationEventWithLog,
  ResetOverdueLog,
  StockLog,
} from '@gmao/types';
import { EventMergeRecord } from '../../../mutations-merger';
import { isAfter } from 'date-fns';
import { downloaderAddFiles } from './files-downloader';
import { getLastMoveLog } from './utils';
import * as Sentry from '@sentry/vue';

// For debug purpose
const DISABLE_AUTO_SYNC = false;
const DISABLE_MUTATIONS_SOCKET_EVENT = false;

export async function syncLocalToMain() {
  if (!DISABLE_AUTO_SYNC && socketIo.connected) {
    const missionId = getMissionId();

    if (await mainHasUpdates(missionId)) {
      missionContextState.hasEventsUpdates = true;
      return;
    }

    const events = (await idb.mutationsEvents.toArray()).filter(
      (item) => !item.syncAt && item.missionId === missionId,
    );

    try {
      await client.operations.mutation.sync({
        missionId,
        events,
      });
    } catch (err) {
      Sentry.captureException(err, {
        data: {
          events,
        },
      });
      throw err;
    }
  }
}

export async function localHasUpdates(missionId: string) {
  return (
    (await idb.mutationsEvents.toArray()).filter(
      (item) => !item.syncAt && item.missionId === missionId,
    ).length > 0
  );
}

export async function mainHasUpdates(missionId: string) {
  return client.operations.query
    .eventsHasUpdates({
      missionId,
      fromDate: await missionConfig.get(missionId, 'syncAt'),
    })
    .then(thenData);
}

export async function needSync(missionId: string) {
  const events = (await idb.mutationsEvents.toArray()).filter(
    (item) => !item.syncAt && item.missionId === missionId,
  );

  if (events.length > 0) return true;
  return mainHasUpdates(missionId);
}

export async function syncEventFromMain(event: MutationEventWithLog) {
  await idb.mutationsEvents.put(event);
  switch (event.type) {
    case 'maintenance':
      await updateMaintenanceFromMain(event.log as MaintenanceLog);
      break;

    case 'move':
      const moveLog = event.log as MoveModuleLog;

      await idb.moves.put(moveLog);

      const lastMoveLog = await getLastMoveLog(moveLog.moduleSerialNumber);
      if (lastMoveLog.eventId === event.id) {
        const module = await idb.modules.get(moveLog.moduleSerialNumber!);
        if (!module) throw new Error('Module not found');

        module.funcLocationId = moveLog.to.funcLocationId;
        module.emplacementId = moveLog.to.emplacementId;
        await idb.modules.put(module);
      }
      break;

    case 'stock':
      const stockLog = event.log as StockLog;

      await idb.stocksLogs.put(stockLog);

      const lastStockLog = await getLastStockLog(stockLog.amosId, stockLog.emplacementId);
      if (lastStockLog.eventId === event.id) {
        const stock = await idb.stocks.get({
          amosId: stockLog.amosId,
          emplacementId: stockLog.emplacementId,
        });

        await idb.stocks.put({
          storeId: stockLog.storeId,
          emplacementId: stockLog.emplacementId,
          amosId: stockLog.amosId,
          amosCode: stock?.amosCode,
          quantity: stockLog.quantityAfter,
        });
      }
      break;

    case 'counter':
      const counterLog = event.log as CounterLog;
      await idb.countersLogs.put(counterLog);
      break;

    case 'resetOverdue':
      const resetOverdueLog = event.log as ResetOverdueLog;
      await idb.resetOverdueLogs.put(resetOverdueLog);
      break;

    case 'machineStatus':
      const machineStatusLog = event.log as MachineStatusLog;
      await idb.machineStatusLogs.put(machineStatusLog);
      break;
  }
}

interface SocketIoMutationEvent {
  missionId: string;
  eventId: string;
}

export async function onMutationEvent({ missionId, eventId }: SocketIoMutationEvent) {
  if (DISABLE_MUTATIONS_SOCKET_EVENT) return;

  if (await idb.missions.get(missionId)) {
    const localEvent = await idb.mutationsEvents.get(eventId);
    console.log('onMutationEvent', { missionId, eventId, localEvent });
    if (localEvent?.syncAt) return;

    const event = await client.operations.query.eventWithLog({ id: eventId }).then(thenData);
    await syncEventFromMain(event);
    await updateSyncAt(missionId, event.syncAt!);
  }

  if (await localHasUpdates(missionId)) {
    missionContextState.hasEventsUpdates = true;
  }
}
socketIo.on('mutationEvent', onMutationEvent);

export async function updateSyncAt(missionId: string, date: string) {
  const current = await missionConfig.get(missionId, 'syncAt');
  if (!current || isAfter(new Date(date), new Date(current))) {
    await missionConfig.set(missionId, 'syncAt', date);
  }
}

async function getLastStockLog(amosId: number, emplacementId: string): Promise<StockLog> {
  const logs = await idb.stocksLogs
    .where({
      amosId,
      emplacementId,
    })
    .reverse()
    .sortBy('logDate');

  return logs[0];
}

export async function getEventsToMerge(missionId: string) {
  const localEvents = (await idb.mutationsEvents.toArray()).filter(
    (item) => !item.syncAt && item.missionId === missionId,
  ) as MutationEventWithLog[];

  for (const event of localEvents) {
    switch (event.type) {
      case 'maintenance':
        event.log = (await idb.maintenances.get({ eventId: event.id }))!;
        break;
      case 'move':
        event.log = (await idb.moves.get({ eventId: event.id }))!;
        break;
      case 'stock':
        event.log = (await idb.stocksLogs.get({ eventId: event.id }))!;
        break;
      case 'counter':
        event.log = (await idb.countersLogs.get({ eventId: event.id }))!;
        break;
      case 'resetOverdue':
        event.log = (await idb.resetOverdueLogs.get({ eventId: event.id }))!;
        break;
      case 'machineStatus':
        event.log = (await idb.machineStatusLogs.get({ eventId: event.id }))!;
        break;
    }
  }

  const lastSync = (await missionConfig.get(missionId, 'syncAt'))!;
  const { items: mainEvents } = await client.operations.query
    .eventsList({
      missionId,
      fromDate: lastSync,
    })
    .then(thenData);

  return [...localEvents.filter((item) => !!item.log), ...mainEvents] satisfies EventMergeRecord[];
}

export function getFilesToUpload(events: MutationEvent[]) {
  return (events.filter((event) => event.type === 'maintenance') as MutationEventMaintenance[])
    .map((event) => [...event.payload.pictures, ...event.payload.documents])
    .flat();
}

async function updateMaintenanceFromMain(log: MaintenanceLog) {
  const files = [...log.pictures, ...log.documents].map((item) => item.uri);

  if (files.length) {
    const localLog = await idb.maintenances.get(log.eventId);

    if (localLog) {
      const localFiles = [...localLog.pictures, ...localLog.documents].map((item) => item.uri);

      // If local have files, update their paths
      for (const filePath of files) {
        const localFile = localFiles.find(
          (localFilePath) => basename(filePath) === basename(localFilePath),
        );
        if (localFile) {
          await idb.files.update(localFile, {
            path: filePath,
          });
        }
      }
    } else {
      // Else add files to download queue
      downloaderAddFiles(...files);
    }
  }

  await idb.maintenances.put(log);
}
