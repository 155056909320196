<template>
  <div>
    <div flex items-center gap-1 mb-2>
      <EventType :type="event.type" />
      <span flex items-center gap-1>
        <n-icon>
          <Calendar />
        </n-icon>
        <DateFormat :value="event.logDate" />
      </span>
    </div>

    <EventDisplay :event="event" :events="events" :merger="merger" />

    <MutationsMergerEventConflict
      v-if="event.conflicts?.length"
      v-model:event="editedEvent"
      :merger="merger"
      mt-2
    />
    <dump :data="event" />
  </div>
</template>

<script lang="ts" setup>
import { Calendar } from '@vicons/carbon';
import { DateFormat, EventMergeRecord, MutationsMerger } from '@gmao/shared';
import MutationsMergerEventConflict from './MutationsMergerEventConflict.vue';
import { computed } from 'vue';
import { EventType } from '@gmao/shared';
import EventDisplay from './EventDisplay.vue';

const props = defineProps<{
  event: EventMergeRecord;
  events: EventMergeRecord[];
  merger: MutationsMerger;
}>();

const emit = defineEmits<{
  (event: 'update:event', payload: EventMergeRecord): unknown;
}>();

const editedEvent = computed({
  get: () => props.event,
  set: (value) => emit('update:event', value),
});
</script>
