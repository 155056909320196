<template>
  <span>{{ formatted }}</span>
</template>

<script lang="ts" setup>
import { format } from 'date-fns';
import { computed } from 'vue';

const props = defineProps<{
  value: string;
  includeHours?: boolean;
}>();

const formatted = computed(() =>
  format(new Date(props.value), props.includeHours ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'),
);
</script>
