<template>
  <n-button
    fixed
    z-100
    bottom-12
    right-4
    circle
    secondary
    size="large"
    :loading="loading"
    @click="openFile"
  >
    <template #icon>
      <n-icon>
        <HelpFilled />
      </n-icon>
    </template>
  </n-button>
</template>

<script lang="ts" setup>
import { HelpFilled } from '@vicons/carbon';
import { ref, watch } from 'vue';
import { getPdfViewerUrl, useDocumentLoader } from '../files';
import { set, get } from 'idb-keyval';
import { useIsOnline } from '@shared/naive-ui';
import { useAuth } from '@shared/logto/vue';
import { windowOpen } from '../windowOpen';

const props = defineProps<{
  path: string;
  offlineMode?: boolean;
}>();

const isOnline = useIsOnline();
const getDocument = useDocumentLoader();
const loading = ref(false);
const { isLogged } = useAuth();

async function openFile() {
  loading.value = true;
  try {
    const useOfflineFile = props.offlineMode && !isOnline.value;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const blob = !useOfflineFile ? await getDocument(props.path) : await get('userGuidelines');
    const fileUrl = URL.createObjectURL(blob);

    windowOpen(getPdfViewerUrl(fileUrl, 'User guidelines'));
  } finally {
    loading.value = false;
  }
}

watch(isLogged, async (value) => {
  if (value && props.offlineMode) {
    try {
      const blob = await getDocument(props.path, undefined, false);
      await set('userGuidelines', blob);
    } catch {
      // Silent error
    }
  }
});
</script>
