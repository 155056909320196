import { useOnline } from '@vueuse/core';
import { ComputedRef, Plugin, computed, inject, ref, watch } from 'vue';
import { io, Socket, ManagerOptions } from 'socket.io-client';

interface OnlineCheckerOptions {
  ping?: {
    interval?: number;
    timeout?: number;
    path?: string;
  };
  socketIo?: true | ManagerOptions | Socket;
}

const pingState = ref(true);
const neworkOnlineState = useOnline();

export const onlineChecker: Plugin<OnlineCheckerOptions> = {
  install(app, options = {}) {
    async function pingCheck() {
      pingState.value = await ping(options.ping?.path, options.ping?.timeout);
    }

    if (!options.socketIo) {
      setInterval(() => pingCheck(), options.ping?.interval || 30_000);
      watch(neworkOnlineState, () => pingCheck());
      pingCheck();
    } else {
      const socket =
        options.socketIo instanceof Socket
          ? options.socketIo
          : io({
              host: (options.socketIo as ManagerOptions).host || window.location.host,
              path: (options.socketIo as ManagerOptions).path || '/api/socket.io',
            });

      socket.on('connect', () => {
        pingState.value = true;
      });
      socket.on('disconnect', () => {
        pingState.value = false;
      });
    }

    app.provide(
      'onlineChecker',
      computed(() => neworkOnlineState.value && pingState.value),
    );
  },
};

async function ping(path = '/api/ping', timeout = 5000) {
  if (!navigator.onLine) return false;
  try {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    await fetch(path, { signal: controller.signal });
    clearTimeout(id);
    return true;
  } catch (err) {
    return false;
  }
}

export function isOnline() {
  return neworkOnlineState.value && pingState.value;
}

export function useIsOnline() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject('onlineChecker')! as ComputedRef<boolean>;
}
