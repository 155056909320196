import { MoveModuleLog } from '@gmao/types';
import { idb } from './idb';

export async function getLastMoveLog(moduleSerialNumber: string): Promise<MoveModuleLog> {
  const logs = await idb.moves
    .where('moduleSerialNumber')
    .equals(moduleSerialNumber)
    .reverse()
    .sortBy('logDate');

  return logs[0];
}
