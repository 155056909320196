<template>
  <n-alert v-if="props.error" class="async-error" title="Error" type="error" mb-4>
    {{ message }}
    <dump v-if="props.error.response" :data="props.error.response" title="Response" />
    <dump :data="stack" title="Stack" />
    <dump :data="full" title="Full error" />
  </n-alert>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { serializeError } from 'serialize-error';

const props = defineProps<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}>();

const message = computed(() => {
  if (props.error.response?.status >= 500) {
    return `Server error, please retry later`;
  }

  return (
    props.error.response?.data.message ||
    props.error.response?.data.error ||
    props.error.error?.message ||
    props.error.message ||
    props.error
  );
});

const stack = computed(() => {
  return props.error.response?.data.stack || props.error.stack;
});

const full = computed(() => {
  return serializeError(props.error);
});
</script>
