import { kv } from '@gmao/sync';
import { computed, inject, Plugin, reactive, toRaw } from 'vue';
import { CartOrigin } from './types';

export interface CartAmos {
  id: number;
  code: string;
  description?: string;
}

export interface CartItem extends CartAmos {
  quantity: number;
  origin: CartOrigin;
}

export interface CartState {
  items: CartItem[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const findItemIndex = (amosId: number, missionId: string) => {
  return (row: CartItem) => row.id === amosId && row.origin.id === missionId;
};

function createCartProvider() {
  const state = reactive<CartState>({
    items: [],
  });

  const sortedItems = computed(() => {
    return state.items.sort((a, b) => a.code.localeCompare(b.code));
  });

  function persist() {
    return kv.set('cart', toRaw(state)).catch(noop);
  }

  function findIndex(amosId: number, missionId: string) {
    return state.items.findIndex(findItemIndex(amosId, missionId));
  }

  function put(item: CartAmos, origin: CartOrigin, quantity = 1) {
    const index = findIndex(item.id, origin.id);
    if (index >= 0) {
      const newQuantity = state.items[index].quantity + quantity;
      const updatedItem: CartItem = {
        ...state.items[index],
        quantity: newQuantity,
      };

      const replaceItems = newQuantity > 0 ? [updatedItem] : [];
      state.items.splice(index, 1, ...replaceItems);
    } else {
      state.items.push({
        id: item.id,
        code: item.code,
        description: item.description,
        origin,
        quantity,
      });
    }
    return persist();
  }

  function setQuantity(amosId: number, missionId: string, quantity: number) {
    const index = findIndex(amosId, missionId);
    if (index >= 0) {
      state.items[index].quantity = quantity;
      return persist();
    }
  }

  function remove(amosId: number, missionId: string) {
    const index = findIndex(amosId, missionId);
    if (index >= 0) {
      state.items.splice(index, 1);
      return persist();
    }
  }

  function clear(originId?: string) {
    originId
      ? (state.items = state.items.filter((item) => item.origin.id !== originId))
      : (state.items = []);
    return persist();
  }

  // Boot: load data from kv
  kv.get<CartState>('cart')
    .then((stored) => {
      state.items = stored?.items || [];
    })
    .catch(noop);

  return {
    items: sortedItems,
    put,
    remove,
    clear,
    findIndex,
    setQuantity,
  };
}

type CartProvider = ReturnType<typeof createCartProvider>;

export const cartPlugin: Plugin = {
  install(app) {
    const provider = createCartProvider();
    app.provide('cart', provider);
  },
};

export function useCart() {
  return inject<CartProvider>('cart', createCartProvider());
}
