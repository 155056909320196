import { BlankLayout } from '@shared/naive-ui';
import { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    name: 'missions',
    path: '/missions',
    alias: '/',
    component: () => import('./pages/MissionsPage.vue'),
    meta: {
      layout: BlankLayout,
      scopes: ['operations'],
    },
  },
];
