/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ComputedRef } from 'vue';
import {
  AxiosAdapter,
  ClientExtras,
  ClientNamespaces,
  ClientResponse,
  asAxios,
  createClient,
} from '@minusjs/tuf/client';
import { descreteApi } from '@shared/naive-ui';

declare module '@minusjs/tuf/client' {
  interface ClientRequestOptions {
    notification?: boolean;
  }
}

export const sharedClient = createClient<ClientNamespaces<any> & ClientExtras>(
  {
    endpoint: '/api/tuf',
  },
  new AxiosAdapter(),
);

sharedClient.adapter.onResponse(undefined, (requestError) => {
  if (requestError.request.options?.notification) {
    descreteApi.notification.error({
      title: 'Error',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      content: (requestError.response?.data as any).message || requestError.message,
      duration: 3000,
    });
  }

  return Promise.reject(requestError);
});

export function useSharedClient<T>() {
  return sharedClient as T & ClientExtras;
}

export function thenData<T extends ClientResponse>(value: T): Promise<T['data']> {
  return Promise.resolve(value.data);
}

export const axiosClient = sharedClient.use(asAxios);
export function useAxios() {
  return axiosClient;
}

export function setupAuthOnClient(auth: {
  token: ComputedRef<string | undefined>;
  fetchToken: () => Promise<string>;
}) {
  const { token, fetchToken } = auth;

  sharedClient.adapter.onRequest((request) => {
    if (!request.options) request.options = { headers: {} };
    if (!request.options.headers) request.options.headers = {};
    request.options.headers.authorization = `Bearer ${token.value}`;
    return request;
  });

  sharedClient.adapter.onResponse(undefined, async (error) => {
    if (error.response?.status === 401) {
      try {
        const token = await fetchToken();
        if (token) {
          return sharedClient.adapter.execute(error.request);
        }
      } catch {
        // Nothing
      }
    }
    throw error;
  });

  axiosClient.interceptors.request.use(async (config) => {
    config.headers['Authorization'] = `Bearer ${token.value}`;
    return config;
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  axiosClient.interceptors.response.use(undefined, async (error: any) => {
    if (error.response?.status === 401) {
      if (!token.value) await fetchToken();
      if (token.value) {
        const config = {
          ...error.config,
          headers: {
            authorization: `Bearer ${token.value}`,
          },
        };
        return axiosClient.request(config);
      }
    }
    throw error;
  });
}
