import { SelectOption } from 'naive-ui';
import { EventMergeConflict, EventMergeConflictType, EventMergeRecord } from './schemas';
import { MutationEventWithLog } from '@gmao/types';
import { MutationsMerger } from './mutations-merger';
import { getFuncLocation, getEmplacement } from '../infra/operations/utils';

export const mergeConflictMessages: Record<EventMergeConflictType, string> = {
  duplicate: 'May be a duplicate',
  maintenanceModuleLocation: 'Module on this log have a different location',
  moveToLocation: 'Module on this log have a different location',
  moveToOccupied: 'Target location is already occupied by another module',
  higherPreviousCounter: 'Previous counter log has higher value',
};

const resolvesOptions: Record<EventMergeConflictType, SelectOption[]> = {
  duplicate: [{ value: 'delete', label: 'Delete this log' }],
  maintenanceModuleLocation: [
    { value: 'followModuleLocation', label: 'Use module location' },
    { value: 'removeModule', label: 'Remove module from this maintenance log' },
    { value: 'delete', label: 'Delete this log' },
  ],
  moveToLocation: [
    { value: 'followModuleLocation', label: 'Use module location' },
    { value: 'delete', label: 'Delete this log' },
  ],
  moveToOccupied: [{ value: 'delete', label: 'Delete this log' }],
  higherPreviousCounter: [{ value: 'delete', label: 'Delete this log' }],
};

export function getMergeConflictResolvesOptions(
  event: MutationEventWithLog,
  conflict: EventMergeConflict,
): SelectOption[] {
  switch (conflict.type) {
    default:
      return resolvesOptions[conflict.type!];
    case 'maintenanceModuleLocation':
      if (event.emplacementId) {
        return [
          { value: 'followModuleLocation', label: 'Use module location' },
          { value: 'delete', label: 'Delete this log' },
        ];
      }
      return resolvesOptions[conflict.type!];
  }
}

async function getModuleLocationAt(event: EventMergeRecord, merger: MutationsMerger) {
  const location = merger.getModuleLocationAt(event.moduleSerialNumber!, new Date(event.logDate));

  if (location) {
    return {
      funcLocation: location.funcLocationId
        ? await getFuncLocation(location.funcLocationId)
        : undefined,
      emplacement: location.emplacementId
        ? await getEmplacement(location.emplacementId)
        : undefined,
    };
  }
}

export async function getConflictResolve(
  type: string,
  event: EventMergeRecord,
  merger: MutationsMerger,
): Promise<EventMergeConflict['resolve']> {
  let lastModuleLocation;
  if (event.moduleSerialNumber) {
    lastModuleLocation = await getModuleLocationAt(event, merger);
  }

  switch (type) {
    default:
      return { type };
    case 'removeModule':
      return {
        type,
        patch: {
          moduleSerialNumber: null,
          'payload.moduleSerialNumber': null,
          'log.moduleSerialNumber': null,
        },
      };
    case 'followModuleLocation':
      return {
        type,
        patch: {
          funcLocationId: lastModuleLocation?.funcLocation?.id,
          emplacementId: lastModuleLocation?.emplacement?.id,
          'payload.funcLocationId': lastModuleLocation?.funcLocation?.id,
          'payload.emplacementId': lastModuleLocation?.emplacement?.id,

          'log.funcLocationId': lastModuleLocation?.funcLocation?.id,
          'log.funcLocationName': lastModuleLocation?.funcLocation?.name,
          'log.machineId': lastModuleLocation?.funcLocation?.machineId,
          'log.machineName': lastModuleLocation?.funcLocation?.machineName,

          'log.emplacementId': lastModuleLocation?.emplacement?.id,
          'log.emplacementName': lastModuleLocation?.emplacement?.name,
          'log.storeId': lastModuleLocation?.emplacement?.storeId,
          'log.storeName': lastModuleLocation?.emplacement?.storeName,
        },
      };
  }
}
