import { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    name: 'spls',
    path: 'spls',
    component: () => import('./pages/SplsPage.vue'),
  },
  {
    name: 'spls.items',
    path: 'spls/:splId',
    component: () => import('./pages/SplItemsPage.vue'),
  },
];
