import LoginView from './components/LoginView.vue';
import CallbackView from './components/CallbackView.vue';
import LogoutView from './components/LogoutView.vue';
import SignInButton from './components/SignInButton.vue';
import SignOutButton from './components/SignOutButton.vue';
import UserName from './components/UserName.vue';
import { RouteRecordRaw } from 'vue-router';

export { LoginView, CallbackView, LogoutView, SignInButton, SignOutButton, UserName };
export * from './auth';
export * from './plugin';
export * from './utils';

export function getAuthRoutes(meta?: RouteRecordRaw['meta']) {
  return [
    {
      name: 'logto.callback',
      path: '/callback',
      component: CallbackView,
      meta,
    },
    {
      name: 'logto.logoutCallback',
      path: '/logout',
      component: LogoutView,
      meta,
    },
    {
      path: '/login',
      name: 'logto.login',
      component: LoginView,
      meta,
    },
  ] satisfies RouteRecordRaw[];
}
