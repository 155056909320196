import { useRouter } from 'vue-router';
import { shallowRef, watch, h, defineComponent, Component, PropType } from 'vue';
import SiderLayout from './SiderLayout.vue';
import TopBarLayout from './TopBarLayout.vue';
import { useUiSettings } from '../../ui-settings';

export const LayoutManager = defineComponent({
  props: {
    default: {
      type: Object as PropType<Component>,
      default: SiderLayout,
    },
  },
  setup(props, { slots }) {
    const currentLayout = shallowRef<Component>(props.default || SiderLayout);
    const router = useRouter();
    const { features } = useUiSettings();

    watch(
      () => router.currentRoute.value,
      (route) => {
        let layout = (route.meta.layout as Component) || props.default || SiderLayout;
        if (features.value.altSidebarMenu && layout === SiderLayout) {
          layout = TopBarLayout;
        }
        if (currentLayout.value !== layout) currentLayout.value = layout;
      },
      {
        immediate: true,
        deep: true,
      },
    );

    return () => h(currentLayout.value, null, slots);
  },
});
