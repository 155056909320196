<template>
  <n-card max-w-500px my-4 mx-auto>
    <div text-center>
      <n-spin size="small" />
    </div>
    <div text-center mt-3>Connecting...</div>
  </n-card>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useAuth } from '../plugin';
import { useRouter } from 'vue-router';

const { loginCallback } = useAuth();

const router = useRouter();
onMounted(() => {
  setTimeout(() => {
    loginCallback()
      .then(() => {
        router.replace('/');
      })
      .catch((error) => {
        router.replace(`/error?message=${error.message || error}`);
      });
  }, 500);
});
</script>
