import { AuthConfig, parseJwt } from '@shared/logto/vue';
import { isOnline } from '@shared/naive-ui';

export const authConfig: AuthConfig = {
  endpoint: import.meta.env.VITE_LOGTO_ENDPOINT,
  appId: 'gmao-operations',
  resource: 'https://gmao',
  scopes: ['profile', 'email', 'identities'],
  onTokenUpdate(token) {
    localStorage.setItem('auth.token', token || '');
  },
  onScopesUpdate(scopes) {
    localStorage.setItem('auth.scopes', JSON.stringify(scopes));
  },
  async contextLoader(loader) {
    const localToken = localStorage.getItem('auth.token');
    const isOffline = !(await isOnline());

    if (localToken && isOffline) {
      const scopes = JSON.parse(localStorage.getItem('auth.scopes') || '[]');
      const payload = parseJwt(localToken);

      sessionStorage.setItem(
        'auth.scopes',
        JSON.stringify({
          token: localToken,
          scopes,
        }),
      );

      return {
        token: localToken,
        user: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: payload!.sub!,
          scopes,
        },
      };
    }
    return loader();
  },
};
