import { differenceInDays, isWithinInterval } from 'date-fns';
import { idb, missionConfig } from './idb';

export async function cleanMission(missionId: string) {
  const mission = await idb.missions.get(missionId);
  if (!mission) return;

  await cleanMissionsTasks(missionId);

  for (const machine of mission.machines) {
    await cleanMachine(machine.id, missionId);
  }

  for (const store of mission.stores) {
    await cleanStore(store.id, missionId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const _spl of mission.spls || []) {
    // TODO
  }

  await idb.missions.delete(missionId);
  await missionConfig.reset(missionId);
}

export async function cleanMachine(machineId: number, fromMissionId?: string) {
  if (await machineUsed(machineId, fromMissionId)) return;

  await cleanNomenclature(machineId);

  const machine = await idb.machines.get(machineId);
  if (!machine) return;

  for (const fl of machine.funcLocations) {
    const logs = await idb.maintenances.where({ funcLocationId: fl.id }).toArray();
    await Promise.all(logs.map((log) => cleanMaintenanceLog(log.eventId, fromMissionId)));
  }

  await idb.machines.delete(machineId);
}

async function machineUsed(machineId: number, excludeMissionId?: string) {
  const missions = await idb.missions.toArray();
  for (const mission of missions) {
    if (mission.id === excludeMissionId) continue;
    if (mission.machines.find((item) => item.id === machineId)) return true;
  }

  return false;
}

async function cleanNomenclature(machineId: number) {
  const nom = await idb.nomenclatures.get(machineId);
  if (!nom) return;

  const othersNomenclatures = (await idb.nomenclatures.toArray()).filter(
    (item) => item.id !== machineId,
  );

  const usedDocsIds = [
    ...othersNomenclatures
      .map((item) => item.certificates)
      .flat()
      .map((item) => item.id),
    ...othersNomenclatures
      .map((item) => item.techDocs)
      .flat()
      .map((item) => item.id),
    ...(othersNomenclatures
      .map((item) => item.amos)
      .flat()
      .map((item) => item.doc?.id)
      .filter(Boolean) as number[]),
  ];

  const nomDocsIds = [
    ...nom.certificates.map((item) => item.id),
    ...nom.techDocs.map((item) => item.id),
    ...(nom.amos.map((item) => item.doc?.id).filter(Boolean) as number[]),
  ];

  const idsToDelete = nomDocsIds.filter((id) => !usedDocsIds.includes(id));
  await idb.nomenclaturesDocs.bulkDelete(idsToDelete);

  if (nom.model) {
    await cleanModel(nom.model.name, machineId);
  }
  await idb.nomenclatures.delete(machineId);
}

async function cleanModel(modelName: string, fromMachineId?: number) {
  if (fromMachineId) {
    const isUsed =
      (await idb.nomenclatures.toArray())
        .filter((item) => item.id !== fromMachineId)
        .filter((item) => item.model?.name === modelName).length > 0;
    if (isUsed) return;
  }
  await idb.models.delete(modelName);
}

export async function cleanStore(storeId: string, fromMissionId?: string) {
  if (await storeUsed(storeId, fromMissionId)) return;

  const store = await idb.stores.get(storeId);
  if (!store) return;

  for (const emplacement of store.emplacements) {
    const logs = await idb.maintenances.where({ emplacementId: emplacement.id }).toArray();
    await Promise.all(logs.map((log) => cleanMaintenanceLog(log.eventId, fromMissionId)));

    // TODO: delete by stocks logs ids for event
    // await idb.stocksLogs.where({ emplacementId: emplacement.id }).delete();
    // await idb.mutationsEvents.where({ emplacementId: emplacement.id }).delete();

    await idb.stocks.where({ emplacementId: emplacement.id }).delete();
  }
  await idb.stores.delete(storeId);
}

async function storeUsed(storeId: string, excludeMissionId?: string) {
  const missions = await idb.missions.toArray();
  for (const mission of missions) {
    if (mission.id === excludeMissionId) continue;
    if (mission.stores.find((item) => item.id === storeId)) return true;
  }

  return false;
}

export async function cleanMissionsTasks(missionId: string) {
  const mission = await idb.missions.get(missionId);
  if (!mission) return;

  const machinesIds = mission.machines.map((item) => item.id);
  const allMachines = await idb.machines.toArray();

  const missionsAmosIds = allMachines
    .filter((item) => machinesIds.includes(item.id))
    .map((item) => item.funcLocations.map((fl) => fl.amosId))
    .flat();

  const othersAmosIds = allMachines
    .filter((item) => !machinesIds.includes(item.id))
    .map((item) => item.funcLocations.map((fl) => fl.amosId))
    .flat();

  const missionTasks = (await idb.tasks.toArray()).filter((item) => {
    for (const amosId of missionsAmosIds) {
      if (item.amosIds.includes(amosId)) return true;
    }
    return false;
  });

  const unusedMissionTasks = missionTasks.filter((task) => {
    for (const amosId of othersAmosIds) {
      if (task.amosIds.includes(amosId)) return false;
    }
    return true;
  });

  await Promise.all(unusedMissionTasks.map((task) => cleanTask(task.id)));
}

async function cleanTask(taskId: string) {
  const task = await idb.tasks.get(taskId);
  if (!task) return;

  if (task.filePath) {
    await idb.files.delete(task.filePath);
  }
  await idb.tasks.delete(taskId);
}

async function cleanMaintenanceLog(eventId: string, fromMissionId?: string) {
  const log = await idb.maintenances.get(eventId);
  if (!log) return;

  if (log.missionId !== fromMissionId) return;

  const checkDate = await missionsDatesCheck(fromMissionId);
  if (checkDate(log.logDate)) return;

  const filesPaths = [
    ...log.pictures.map((item) => item.uri),
    ...log.documents.map((item) => item.uri),
  ];

  if (filesPaths.length) {
    await idb.files.bulkDelete(filesPaths);
  }

  await idb.mutationsEvents.delete(eventId);
  await idb.maintenances.delete(eventId);
}

export async function cleanUploads() {
  const files = await idb.files.where('path').startsWith('uploads/').toArray();
  for (const file of files) {
    const diff = differenceInDays(new Date(file.createdAt), new Date());
    if (diff > 7) {
      await idb.files.delete(file.path);
    }
  }
}

async function missionsDatesCheck(excludeId?: string) {
  const missions = (await idb.missions.toArray()).filter((item) => {
    if (excludeId && item.id === excludeId) return false;
    return true;
  });

  const configs: { id: string; fromDate?: string }[] = [];
  for (const mission of missions) {
    configs.push({
      id: mission.id,
      fromDate: await missionConfig.get(mission.id, 'fromDate'),
    });
  }

  return (date: string): boolean => {
    for (const mission of missions) {
      const config = configs.find((item) => item.id === mission.id);

      const startDate = config?.fromDate || mission.startDate;
      const inInterval = isWithinInterval(new Date(date), {
        start: new Date(startDate),
        end: mission.endDate ? new Date(mission.endDate) : new Date(),
      });
      if (inInterval) return true;
    }
    return false;
  };
}
