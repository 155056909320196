import { createDiscreteApi, darkTheme } from 'naive-ui';

export const descreteApi = createDiscreteApi(['notification'], {
  configProviderProps: {
    theme: darkTheme,
  },
  notificationProviderProps: {
    containerStyle: 'top: 50px;',
  },
});
