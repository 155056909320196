/* eslint-disable @typescript-eslint/no-non-null-assertion */
import LogtoClient, { LogtoConfig, UserInfoResponse } from '@logto/browser';
import { computed, reactive, ComputedRef } from 'vue';
import { JwtPayload, parseJwt } from './utils';
import { computedWithControl, computedAsync } from '@vueuse/core';
import { Router } from 'vue-router';
import { ClientNamespaces } from '@minusjs/tuf/client';
import type { LogtoModule } from '../api';
import { DefaultJwtVerifier } from './jwtVerifier';

export interface AuthConfig extends Omit<LogtoConfig, 'resources'> {
  resource: string;
  onTokenUpdate?: (token?: string) => unknown;
  onScopesUpdate?: (scopes: string[]) => unknown;
  contextLoader?: (loader: () => Promise<AuthContext>) => Promise<AuthContext>;
}

export interface AuthUser {
  id: string;
  scopes: string[];
}

interface AuthContext {
  token?: string;
  user: AuthUser;
}

export type AuthClient = ClientNamespaces<LogtoModule['options']['tuf']>;
export type AuthProvider = ReturnType<typeof createAuth>;

export interface AuthCreateOptions {
  config: AuthConfig;
  client: AuthClient;
}

export function createAuth({ config, client }: AuthCreateOptions) {
  if (!config.contextLoader) config.contextLoader = loadContext;

  const callbackUri = new URL('/callback', window.location.origin).href;
  const logto = new LogtoClient({ ...config, resources: [config.resource] });
  logto.setJwtVerifier((client) => new DefaultJwtVerifier(client));

  const context = reactive({ user: anonymousUser() } as AuthContext);

  async function loadContext(): Promise<AuthContext> {
    const token = sessionStorage.getItem('auth.token') || undefined;
    const payload = parseJwt(token || '');

    if ((payload?.exp || 0) < new Date().getTime() / 1000) {
      sessionStorage.setItem('auth.token', '');
      return { user: anonymousUser() };
    }

    const user: AuthUser = payload
      ? {
          id: payload.sub,
          scopes: ['connected', ...payload.scope.split(' ')],
        }
      : anonymousUser();

    return {
      token,
      user,
    };
  }

  async function loadScopes(token?: string): Promise<string[]> {
    if (token) {
      const stored = JSON.parse(sessionStorage.getItem('auth.scopes') || '{}');
      if (stored.token === token && stored.scopes?.length) {
        return stored.scopes;
      }

      const { data: scopes } = await client.logto.query.scopes(undefined, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      sessionStorage.setItem('auth.scopes', JSON.stringify({ token, scopes }));
      return scopes;
    }
    sessionStorage.setItem('auth.scopes', JSON.stringify({ token: '', scopes: [] }));
    return [];
  }

  function login() {
    return logto.signIn(callbackUri);
  }

  async function setToken(token?: string) {
    if (config.onTokenUpdate) config.onTokenUpdate(token);

    sessionStorage.setItem('auth.token', token || '');
    context.token = token;

    if (token) {
      const { sub } = parseJwt(token) as JwtPayload;
      context.user = { id: sub, scopes: [] };
    } else {
      context.user = anonymousUser();
    }
    context.user.scopes = await loadScopes(token);
    if (config.onScopesUpdate) config.onScopesUpdate(context.user.scopes);
  }

  async function loginCallback() {
    await logto.handleSignInCallback(window.location.href);
    await fetchToken();
  }

  async function logout() {
    setToken();

    const user = await fetchUserInfos();

    if (user.email?.endsWith('@ldtravocean.com')) {
      const redirectUrl = new URL(`/logout?azureAd=1`, window.location.origin);
      return logto.signOut(redirectUrl.href);
    }
    const redirectUrl = new URL(`/logout`, window.location.origin);
    return logto.signOut(redirectUrl.href);
  }

  async function fetchToken() {
    const sessionContext = await config.contextLoader!(loadContext);
    if (sessionContext.token) {
      await setToken(sessionContext.token);
      return sessionContext.token;
    }

    const token = await logto.getAccessToken(config.resource);
    await setToken(token);
    return token;
  }

  function _can(scope: string) {
    return context.user.scopes.includes(scope);
  }

  function _canOr(scopes: string[]) {
    for (const scope of scopes) {
      if (_can(scope)) return true;
    }
    return false;
  }

  function can(scope: string) {
    return computedWithControl(
      () => context.user.scopes,
      () => _can(scope),
    ) as ComputedRef<boolean>;
  }

  function canOr(scopes: string[]) {
    return computedWithControl(
      () => context.user.scopes,
      () => _canOr(scopes),
    ) as ComputedRef<boolean>;
  }

  function setupRouter(router: Router) {
    router.beforeEach(async (to, _, next) => {
      if (to.meta.scopes) {
        try {
          await fetchToken();
          const can = _canOr(to.meta.scopes as string[]);
          if (!can) {
            if (!context.token) return next('/login');
            return next(
              '/error?message=You are not authorized to view this page. Please contact an administrator.',
            );
          }
        } catch {
          return next('/login');
        }
      }
      return next();
    });
  }

  function fetchUserInfos() {
    return logto.fetchUserInfo();
  }

  return {
    user: computed(() => context.user),
    userInfos: computedAsync(() => {
      if (!context.token) return {} as UserInfoResponse;
      return fetchUserInfos();
    }, null),
    login,
    loginCallback,
    logout,
    can,
    canOr,
    setupRouter,
    isLogged: computed(() => !!context.user.id),
    token: computed(() => context.token),
    loadContext,
    fetchToken,
    fetchUserInfos,
  };
}

function anonymousUser() {
  return { id: '', scopes: [] };
}
