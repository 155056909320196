<template>
  <n-spin class="async-handler" :show="showSpin && status.isLoading">
    <AsyncError :error="status.error" />
    <slot v-if="!hideOnError || (hideOnError && !status.error)" />
  </n-spin>
</template>

<script lang="ts" setup>
import { useLoadingBar } from 'naive-ui';
import { watch } from 'vue';
import AsyncError from './AsyncError.vue';
import { UseAsyncFuncStatus } from './useAsyncFunc';

const props = defineProps<{
  status: UseAsyncFuncStatus;
  hideOnError?: boolean;
  showSpin?: boolean;
}>();

const loadingBar = useLoadingBar();

watch(
  () => props.status,
  ({ error, isLoading, isReady }) => {
    if (isLoading) {
      loadingBar.start();
    } else if (error) {
      setTimeout(() => loadingBar.error(), 50);
    } else if (isReady) {
      setTimeout(() => loadingBar.finish(), 100);
    }
  },
  { immediate: true },
);
</script>
