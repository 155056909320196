<template>
  <BaseTag
    v-if="id"
    :class="{
      [bgColor]: true,
    }"
  >
    {{ label }}
  </BaseTag>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import BaseTag from './BaseTag.vue';

const props = defineProps<{
  id: string;
}>();

const bgColor = computed(() => {
  switch (props.id) {
    case 'in':
      return 'bg-green-500';
    case 'standby':
      return 'bg-yellow-500';
    case 'off':
      return 'bg-orange-500';
  }
  return '';
});

const label = computed(() => {
  switch (props.id) {
    case 'in':
      return 'In Service';
    case 'standby':
      return 'Stand-by';
    case 'off':
      return 'Off Service';
  }
  return 'Refit';
});
</script>
