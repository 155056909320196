import { OperationMission } from '@gmao/types';
import { reactive } from 'vue';

export interface MissionContext {
  isLoading: boolean;
  mission?: OperationMission;
  currentMachineId?: number;
  hasUpdates: boolean;
  hasEventsUpdates: boolean;
}

export const missionContextState = reactive<MissionContext>({
  isLoading: false,
  mission: undefined,
  currentMachineId: undefined,
  hasUpdates: false,
  hasEventsUpdates: false,
});

export function getMissionId(): string {
  if (!missionContextState.mission?.id) {
    throw new Error('Cannot determine current mission ID in this context');
  }
  return missionContextState.mission?.id;
}
