/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Plugin, inject } from 'vue';
import { AuthCreateOptions, AuthProvider, createAuth } from './auth';

export const authPlugin: Plugin<AuthCreateOptions> = {
  install(app, options) {
    const auth = createAuth(options);
    app.provide('auth', auth);
  },
};

export function useAuth(): AuthProvider {
  return inject('auth')!;
}
