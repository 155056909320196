<template>
  <component :is="is" v-if="is" :event="event" :events="events" :merger="merger" />
</template>

<script lang="ts" setup>
import { MutationTypes } from '@gmao/types';
import {
  EventCounter,
  EventMachineStatus,
  EventMaintenance,
  EventMergeRecord,
  EventMoveModule,
  EventResetOverdue,
  MutationsMerger,
} from '@gmao/shared';
import { type Component, computed } from 'vue';

const props = defineProps<{
  event: EventMergeRecord;
  events: EventMergeRecord[];
  merger: MutationsMerger;
}>();

const components: Record<MutationTypes, Component | undefined> = {
  maintenance: EventMaintenance,
  move: EventMoveModule,
  counter: EventCounter,
  machineStatus: EventMachineStatus,
  resetOverdue: EventResetOverdue,
  stock: undefined, // No display for merger (included in maintenance logs)
};

const is = computed(() => components[props.event.type]);
</script>
