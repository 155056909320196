import Dexie, { Table } from 'dexie';
import {
  OperationMission,
  OperationMachine,
  OperationTask,
  MaintenanceLog,
  OperationStore,
  OperationSpl,
  OperationModule,
  OperationStock,
  MoveModuleLog,
  MutationEvent,
  StockLog,
  CounterLog,
  ResetOverdueLog,
  MachineStatusLog,
} from '@gmao/types';
import type { AssetDataMachineData, MaintenanceCreateInput } from '@gmao/types';

export interface OperationFile {
  path: string;
  blob: Blob;
  createdAt: string;
}

export interface MachineDoc {
  id: number;
  updatedAt: string;
  file: Blob;
}

export interface MachineModel {
  name: string;
  files: {
    path: string;
    updatedAt: string;
    data: Blob;
  }[];
}

export interface MaintenanceLogDraft extends MaintenanceCreateInput {
  eventId: string;
}

export class Idb extends Dexie {
  public missions!: Table<OperationMission>;
  public machines!: Table<OperationMachine>;
  public tasks!: Table<OperationTask>;
  public stores!: Table<OperationStore>;
  public spls!: Table<OperationSpl>;
  public nomenclatures!: Table<AssetDataMachineData & { id: number }>;

  public modules!: Table<OperationModule>;
  public stocks!: Table<OperationStock>;

  public mutationsEvents!: Table<MutationEvent>;
  public maintenances!: Table<MaintenanceLog>;
  public maintenancesDrafts!: Table<MaintenanceLogDraft>;
  public moves!: Table<MoveModuleLog>;
  public stocksLogs!: Table<StockLog>;
  public countersLogs!: Table<CounterLog>;
  public resetOverdueLogs!: Table<ResetOverdueLog>;
  public machineStatusLogs!: Table<MachineStatusLog>;

  public files!: Table<OperationFile>;
  public nomenclaturesDocs!: Table<MachineDoc>;
  public models!: Table<MachineModel>;

  constructor() {
    super('operations');

    this.version(28).stores({
      missions: '&id',
      machines: '&id',
      tasks: '&id',
      stores: '&id',
      spls: '&id',
      nomenclatures: '&id',

      modules: '&serialNumber,amosId,emplacementId,funcLocationId',
      stocks: '[emplacementId+amosId],storeId,amosId,emplacementId',

      mutationsEvents:
        '&id,type,missionId,syncAt,logDate,taskId,machineId,funcLocationId,moduleSerialNumber,storeId,emplacementId',
      maintenances: '&eventId,id,missionId,taskId,funcLocationId,moduleSerialNumber,emplacementId',
      maintenancesDrafts: '&eventId',
      moves:
        '&eventId,id,missionId,moduleSerialNumber,to.funcLocationId,to.emplacementId,from.funcLocationId,from.emplacementId',
      stocksLogs: '&eventId,id,amosId,emplacementId,storeId,maintenanceLogEventId',
      countersLogs: '&eventId,id,funcLocationId,moduleSerialNumber',
      resetOverdueLogs: '&eventId,id,funcLocationId,taskId,moduleSerialNumber',
      machineStatusLogs: '&eventId,id,machineId,status',

      files: '&path',
      nomenclaturesDocs: '&id,updatedAt',
      models: '&name',
    });
  }
}

export const idb = new Idb();
