import { MutationTypes } from '@gmao/types';

export const eventTypesLabels: Record<MutationTypes, string> = {
  counter: 'Counter',
  machineStatus: 'Machine status change',
  maintenance: 'Maintenance',
  move: 'Module movement',
  resetOverdue: 'Reset overdue',
  stock: 'Stock change',
};

// @unocss-include
export const eventTypesColors: Record<MutationTypes, string> = {
  counter: 'bg-slate-600',
  machineStatus: 'bg-indigo-600',
  maintenance: 'bg-green-600',
  move: 'bg-sky-600',
  resetOverdue: 'bg-truegray-600',
  stock: 'bg-violet-600',
};

export const eventTypesShortLabels: Record<MutationTypes, string> = {
  move: 'MOVE',
  counter: 'COUNT',
  stock: 'STOCK',
  maintenance: 'MAINT',
  machineStatus: 'MSTAT',
  resetOverdue: 'RESET',
};
