import { ClientUploadProgressPayload } from '@minusjs/tuf/client';
import { Files } from '../../ports/files';
import { OperationFile } from '../operations/sync/idb';
import { client, thenData, useAxios } from './api';

class ManagementFiles implements Files {
  public async save(
    filename: string,
    blob: Blob,
    progress?: (payload: ClientUploadProgressPayload) => unknown,
    path?: string,
  ): Promise<OperationFile> {
    path ||= `uploads/gmao/${new Date().getTime()}-${filename}`;
    const file: OperationFile = {
      path,
      blob,
      createdAt: new Date().toISOString(),
    };

    file.path = await client.files.mutation
      .upload(
        {
          file: blob,
          path,
        },
        {
          onUploadProgress: progress,
        },
      )
      .then(thenData);

    return file;
  }

  public async get(
    path: string,
    progress?: ((progres: number) => unknown) | undefined,
  ): Promise<OperationFile> {
    const fileUrl = `/api/files/get/${path}`;

    const axios = useAxios();
    const { data: blob } = await axios.get(fileUrl, {
      responseType: 'blob',
      onDownloadProgress(event) {
        if (progress) progress(event.progress || 0);
      },
    });

    return {
      path,
      blob,
      createdAt: new Date().toISOString(),
    };
  }
}

export const files = new ManagementFiles();
