<script lang="ts" setup>
import { useAuth } from '@shared/logto/vue';
import { useRouter } from 'vue-router';
import { setupAuthOnClient } from '@operations/client';

const { token, fetchToken, setupRouter } = useAuth();

setupRouter(useRouter());
setupAuthOnClient({ token, fetchToken });
</script>

<template>
  <slot />
</template>
