<template>
  <div max-w-600px mx-auto>
    <n-alert type="error" title="Error" m-4>
      <template v-if="notFound">Page not found</template>
      <template v-else-if="route.query.message">{{ route.query.message }}</template>
      <template v-else>Something go wrong, please retry later </template>
      <br />
      <n-button secondary my-2 @click="retry">Retry</n-button>
      <n-button v-if="user.id" secondary my-2 ml-2 @click="logout">Logout</n-button>
    </n-alert>
  </div>
</template>

<script lang="ts" setup>
import { useAuth } from '@shared/logto/vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';

const { logout, user } = useAuth();

const props = defineProps<{
  notFound?: boolean;
}>();

const router = useRouter();
const route = useRoute();

function retry() {
  if (props.notFound) {
    router.back();
    return;
  }
  window.location.replace((route.query.returnTo as string) || '/');
}
</script>
