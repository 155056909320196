<template>
  <div
    v-if="isDev"
    px-2
    py-1
    bg-red-500
    text-light-500
    fixed
    bottom-10px
    rounded-lg
    class="app-version"
  >
    Development version
  </div>
</template>

<script lang="ts" setup>
const appVersion = import.meta.env.VITE_APP_VERSION;
const isDev = appVersion === 'dev' || !appVersion;
</script>

<style>
.app-version {
  left: 50%;
  transform: translateX(-50%);
}
</style>
