<template>
  <n-collapse
    v-if="enabled"
    :default-expanded-names="opened ? ['debug'] : []"
    class="dev-debug"
    my-2
  >
    <n-collapse-item :title="finalTitle" name="debug">
      <template #arrow>
        <n-icon>
          <Debug />
        </n-icon>
      </template>
      <pre bg-dark-900 p-2 max-h-400px overflow-y-auto>{{ data }}</pre>
    </n-collapse-item>
  </n-collapse>
</template>

<script lang="ts" setup>
import { Debug } from '@vicons/carbon';
import { computed } from 'vue';
import { useDebug } from './debug';

const props = defineProps<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  title?: string;
  opened?: boolean;
}>();

const { enabled } = useDebug();
const finalTitle = computed(() => {
  return props.title ? props.title : 'Debug';
});
</script>

<style lang="postcss">
.n-collapse.dev-debug {
  .n-collapse-item .n-collapse-item__content-wrapper .n-collapse-item__content-inner {
    padding: 0;
  }
}

body.light {
  .dev-debug {
    pre {
      color: #fff;
    }
  }
}

code {
  background-color: rgba(15, 15, 15, 1);
  padding: 0 3px;
  border-radius: 2px;
}
</style>
