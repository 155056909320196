<template>
  <div>
    <div font-bold>{{ log.taskName }}</div>
    <div v-if="log.comment" mt-2>Comment: {{ log.comment }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ResetOverdueLog } from '@gmao/types';
import { EventMergeRecord } from '@gmao/shared';

const props = defineProps<{
  event: EventMergeRecord;
  events: EventMergeRecord[];
}>();

const log = computed(() => props.event.log as ResetOverdueLog);
</script>
