import { kv } from './kv';

export interface MissionConfig {
  gmaoUpdatedAt?: string;
  nomUpdatedAt?: string;
  dataUpdatedAt?: string;
  filesUpdatedAt?: string;
  fullDownloadAt?: string;
  syncAt?: string;
  fromDate?: string;
  machinesIds?: number[];
  with3dModels?: boolean;
}

class MissionConfigStore {
  public async get<T extends keyof MissionConfig>(
    missionId: string,
    key: T,
  ): Promise<MissionConfig[T] | undefined> {
    const data = (await kv.get(`missionConfig::${missionId}`)) || {};
    return data[key];
  }

  public async set<T extends keyof MissionConfig>(
    missionId: string,
    key: T,
    value: MissionConfig[T] | undefined,
  ): Promise<void> {
    const data: MissionConfig = (await kv.get(`missionConfig::${missionId}`)) || {};
    data[key] = value;
    await kv.set(`missionConfig::${missionId}`, data);
  }

  public async reset(missionId: string) {
    await kv.set(`missionConfig::${missionId}`, {});
  }
}

export const missionConfig = new MissionConfigStore();
