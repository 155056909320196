import { createRouter, createWebHistory } from 'vue-router';
import { BlankLayout } from '@shared/naive-ui';
import { getAuthRoutes } from '@shared/logto/vue';
import { ErrorView } from '@shared/utils';
import { missionsRoutes } from './features/missions';
import { machinesRoutes } from './features/machines';
import { storesRoutes } from './features/stores';
import { splsRoutes } from './features/spls';
import { debugRoutes } from './features/debug';
import { historyRoutes } from './features/history';
import { dashboardRoutes } from './features/dashboard';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'error',
      path: '/error',
      component: ErrorView,
      meta: {
        layout: BlankLayout,
      },
    },
    ...getAuthRoutes({
      layout: BlankLayout,
    }),
    ...debugRoutes,
    ...missionsRoutes,
    {
      path: '/mission/:missionId',
      component: () => import('./features/missions/pages/MissionRootPage.vue'),
      children: [
        {
          path: 'updater',
          name: 'mission.updater',
          component: () => import('./features/missions/pages/MissionUpdaterPage.vue'),
        },
        ...machinesRoutes,
        ...storesRoutes,
        ...splsRoutes,
        ...historyRoutes,
        ...dashboardRoutes,
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: ErrorView,
      meta: {
        layout: BlankLayout,
      },
      props: {
        notFound: true,
      },
    },
  ],
});
