<template>
  <div flex items-center gap-1>
    <MachineName :name="log.machineName" /> <MaintenanceType :id="log.status" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { MachineStatusLog } from '@gmao/types';
import { EventMergeRecord, MachineName, MaintenanceType } from '@gmao/shared';

const props = defineProps<{
  event: EventMergeRecord;
  events: EventMergeRecord[];
}>();

const log = computed(() => props.event.log as MachineStatusLog);
</script>
