import type { AppTufs } from '@gmao/types';
import type { ClientExtras } from '@minusjs/tuf/client';
import { sharedClient, useSharedClient } from '@shared/client';

export * from '@shared/client';

export const client: AppTufs & ClientExtras = sharedClient as AppTufs & ClientExtras;

export function useClient(): AppTufs & ClientExtras {
  return useSharedClient<AppTufs>();
}
