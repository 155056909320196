<template>
  <img v-if="srcUrl" :src="srcUrl" cursor-pointer @click="onClick" />
</template>

<script lang="ts" setup>
import { files } from '@gmao/client';
import { windowOpen } from '@shared/utils';
import { computedAsync } from '@vueuse/core';

const props = defineProps<{
  uri?: string;
}>();

const srcUrl = computedAsync(async () => {
  if (props.uri) {
    const { blob } = await files.get(props.uri);
    const url = URL.createObjectURL(blob);
    return url;
  }
  return '';
});

async function onClick() {
  windowOpen(srcUrl.value);
}
</script>
