<template>
  <n-button text text-left @click="onClick">
    <template #icon>
      <n-icon><DocumentPdf /></n-icon>
    </template>
    {{ label || name }}
  </n-button>
</template>

<script lang="ts" setup>
import { files } from '@gmao/client';
import { windowOpen } from '@shared/utils';
import { DocumentPdf } from '@vicons/carbon';
import { basename } from 'pathe';
import { computed, ref } from 'vue';

const props = defineProps<{
  label?: string;
  uri: string;
}>();

const name = computed(() => basename(props.uri).replace(/^[0-9]+-/, ''));

const loading = ref(false);
async function onClick() {
  try {
    loading.value = true;
    const { blob } = await files.get(props.uri);
    const url = URL.createObjectURL(blob);
    windowOpen(url);
  } finally {
    loading.value = false;
  }
}
</script>
