<template>
  <n-config-provider :theme="configDarkTheme">
    <n-notification-provider container-style="top: 50px">
      <n-loading-bar-provider>
        <n-dialog-provider>
          <ModalManagerSetup>
            <n-global-style />
            <slot />
          </ModalManagerSetup>
        </n-dialog-provider>
      </n-loading-bar-provider>
    </n-notification-provider>
  </n-config-provider>
</template>

<script lang="ts" setup>
import { darkTheme } from 'naive-ui';
import { computed, watch } from 'vue';
import { useUiSettings } from './ui-settings';
import { ModalManagerSetup } from './modal-manager';

const uiSettings = useUiSettings();
const configDarkTheme = computed(() => {
  return uiSettings.darkMode.value ? darkTheme : undefined;
});

watch(
  uiSettings.darkMode,
  (value) => {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(value ? 'dark' : 'light');
  },
  { immediate: true },
);
</script>
