export interface JwtPayload {
  sub: string;
  exp: number;
  scope: string;
}

export function parseJwt(token: string): JwtPayload | null {
  try {
    const [, encoded] = token.split('.');
    const json = atob(encoded || '');
    return JSON.parse(json || 'null');
  } catch {
    return null;
  }
}
