<template>
  <div flex items-center gap-1>
    <MachineName :name="log.machineName" />
    <FuncLocation :name="log.funcLocationName" />
    <span font-bold text-lg>{{ log.counter }}hrs</span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { CounterLog } from '@gmao/types';
import { EventMergeRecord, FuncLocation, MachineName } from '@gmao/shared';

const props = defineProps<{
  event: EventMergeRecord;
  events: EventMergeRecord[];
}>();

const log = computed(() => props.event.log as CounterLog);
</script>
