<template>
  <n-menu
    class="main-menu"
    :collapsed-width="64"
    :collapsed-icon-size="22"
    :options="filteredItems"
    :value="selected"
    :node-props="nodeProps"
    flex
    flex-col
    min-h-100vh
    @update-value="onClickItem"
  />
</template>

<script lang="ts" setup>
import { MenuOption, NIcon } from 'naive-ui';
import { type Component, ref, watch, h, computed } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import {
  Carbon3DMprToggle,
  ExpandAll,
  MessageQueue,
  DataBaseAlt,
  LicenseMaintenance,
} from '@vicons/carbon';

const route = useRoute();

type MenuItem = MenuOption & {
  action?: () => unknown;
  can?: string[];
};

function renderLabelLink(label: string, routeName: string) {
  return () =>
    h(
      RouterLink,
      {
        to: {
          name: routeName,
          params: {
            missionId: route.params.missionId || '-',
          },
        },
      },
      { default: () => label },
    );
}

const items: MenuItem[] = [
  {
    key: 'dashboard',
    label: renderLabelLink('Dashboard', 'dashboard.maintenancePlan'),
    icon: renderIcon(LicenseMaintenance),
    can: ['operations'],
  },
  {
    key: 'machines',
    label: renderLabelLink('Assets & modules', 'machines.structures'),
    icon: renderIcon(Carbon3DMprToggle),
    can: ['operations'],
  },
  {
    key: 'stores',
    label: renderLabelLink('Stores', 'stores'),
    icon: renderIcon(MessageQueue),
    can: ['operations'],
  },
  {
    key: 'spls',
    label: renderLabelLink('Spare Parts Lists', 'spls'),
    icon: renderIcon(ExpandAll),
    can: ['operations'],
  },
  {
    key: 'history',
    label: renderLabelLink('Logs history', 'history'),
    icon: renderIcon(DataBaseAlt),
    can: ['operations'],
  },
];

function nodeProps(item: MenuItem) {
  if (item.key === 'logout') {
    return { class: 'logout color-yellow-400' };
  }
  return {};
}

const filteredItems = computed(() => {
  return items.filter(() => {
    // if (item.can && !unref(canOr(item.can))) return false;
    return true;
  });
});

const selected = ref('fl-modules');
watch(
  () => route.name,
  (value) => {
    selected.value = '';
    if (typeof value === 'string') {
      selected.value = value.split('.')[0];
    }
  },
  {
    immediate: true,
  },
);

function onClickItem(key: string, item: unknown) {
  const { action } = item as MenuItem;
  if (action) return action();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderIcon(icon: Component, props: any = null) {
  return () => h(NIcon, props, { default: () => h(icon) });
}
</script>

<style>
.n-menu .n-menu-item.logout {
  margin-top: auto;
}
</style>
