<template>
  <n-card max-w-500px my-4 mx-auto>
    <div text-center>
      <n-spin size="small" />
    </div>
    <div text-center mt-3>Disconnecting...</div>
  </n-card>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
const azureAdLogoutUrl = `https://login.microsoftonline.com/031e81af-bb46-4833-a61c-f97d61318316/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;

onMounted(() => {
  if (route.query.azureAd) {
    window.location.href = azureAdLogoutUrl;
    return;
  }
  router.replace('/');
});
</script>
