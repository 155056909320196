import { asAxios } from '@minusjs/tuf/client';
import { client } from '@operations/client';
import { reactive, computed } from 'vue';
import { socketIo } from './socket-io';
import { idb, kv } from './idb';

let stop = false;
const state = reactive({
  files: [] as string[],
  isDownloading: false,
  fileProgress: 0,
});

async function downloadFile(path: string) {
  const fileUrl = `/api/files/get/${path}`;
  const axios = client.use(asAxios);
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    onDownloadProgress(event) {
      state.fileProgress = event.progress || 0;
    },
  });
  return blob;
}

async function start() {
  if (!socketIo.connected && !state.files.length) return;
  state.isDownloading = true;

  while (state.files.length) {
    if (stop) {
      stop = false;
      break;
    }

    const path = state.files.shift();
    if (path) {
      const blob = await downloadFile(path);
      await idb.files.put({
        path,
        blob,
        createdAt: new Date().toISOString(),
      });
      await kv.set(`bgDownloader`, state.files);
    }
  }

  state.isDownloading = false;
  stop = false;
}

export async function downloaderAddFiles(...files: string[]) {
  state.files = (await kv.get(`bgDownloader`)) || [];
  state.files.push(...files);
  await kv.set(`bgDownloader`, state.files);
  if (!state.isDownloading) start();
}

export const downloaderState = computed(() => state);

socketIo.on('disconnect', () => {
  if (state.isDownloading) stop = true;
});

socketIo.on('connect', () => {
  start();
});
