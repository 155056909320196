import { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  {
    name: 'machines',
    path: 'machines',
    component: () => import('./pages/MachineRootPage.vue'),
    children: [
      {
        path: '',
        name: 'machines.router',
        component: () => import('./pages/MachineStructuresPage.vue'),
        children: [
          {
            path: '',
            name: 'machines.structures',
            component: () => import('./pages/MachineFuncLocationsPage.vue'),
          },
          {
            path: 'tech',
            name: 'machines.structures.techBreakdown',
            component: () => import('./pages/MachineTechBreakdownPage.vue'),
          },
          {
            path: 'viewer',
            name: 'machines.structures.viewer',
            component: () => import('./pages/MachineViewer.vue'),
          },
        ],
      },
      {
        path: 'modules',
        name: 'machines.modules',
        component: () => import('./pages/ModulesPage.vue'),
      },
      {
        path: 'tech-docs',
        name: 'machines.techDocs',
        component: () => import('./pages/MachineTechDocsPage.vue'),
      },
      {
        path: 'certificates',
        name: 'machines.certificates',
        component: () => import('./pages/MachineCertificatesPage.vue'),
      },
    ],
  },
];
