/* eslint-disable no-case-declarations */
import set from 'just-safe-set';
import { EventMergeConflict, EventMergeModuleLocation, EventMergeRecord } from './schemas';
import { compareAsc, compareDesc, isAfter, isBefore } from 'date-fns';
import {
  MutationEventMaintenance,
  MutationEventMoveModule,
  MutationLog,
  MoveModuleLog,
  MutationEvent,
  CounterLog,
  MachineStatusLog,
  StockLog,
} from '@gmao/types';
import { idb } from '../infra/operations/sync/idb';
import { getLastMoveLog } from '../infra/operations/sync/utils';

export class MutationsMerger {
  private events: EventMergeRecord[];

  constructor(records: EventMergeRecord[] = []) {
    this.events = this.prepareRecords(records);
  }

  public run(records?: EventMergeRecord[]) {
    if (records) this.events = this.prepareRecords(records);

    for (const event of this.events) {
      if (!event.conflicts) event.conflicts = [];

      if (!!event.syncAt || this.hasDeleteResolve(event.conflicts)) {
        continue;
      }

      if (event.type === 'maintenance' && event.moduleSerialNumber) {
        this.checkMaintenance(event);
      } else if (event.type === 'move') {
        this.checkMove(event);
      } else if (event.type === 'counter') {
        this.checkCounter(event as EventMergeRecord<CounterLog>);
      } else if (event.type === 'machineStatus') {
        this.checkMachineStatus(event as EventMergeRecord<MachineStatusLog>);
      }

      const duplicatesIds = this.hasPossibleDuplicates(event);
      if (duplicatesIds?.length) {
        conflictMerge({ type: 'duplicate', duplicatesEventsIds: duplicatesIds }, event);
      } else {
        removeDuplicateConflict(event);
      }
    }
    return this.events;
  }

  public getMainEvents(): EventMergeRecord[] {
    return this.events.filter((item) => !!item.syncAt);
  }

  public getEventsToSync(): EventMergeRecord[] {
    return this.events.filter((item) => !item.syncAt);
  }

  private checkMaintenance(event: EventMergeRecord) {
    const location = this.getModuleLocationAt(event.moduleSerialNumber!, new Date(event.logDate));

    if (
      location &&
      ((event.funcLocationId && event.funcLocationId !== location.funcLocationId) ||
        (event.emplacementId && event.emplacementId !== location.emplacementId))
    ) {
      conflictMerge({ type: 'maintenanceModuleLocation' }, event);
    }
  }

  private checkMove(event: EventMergeRecord) {
    if (!isMoveEvent(event)) return;

    // Destination FL is already occupied by another module
    if (event.payload.toFuncLocationId) {
      const moduleOnFuncLocation = this.getFuncLocationModuleAt(
        event.payload.toFuncLocationId,
        new Date(event.logDate),
      );
      if (moduleOnFuncLocation && moduleOnFuncLocation !== event.moduleSerialNumber) {
        conflictMerge({ type: 'moveToOccupied' }, event);
      }
    }

    // Destination do not match with module location
    const location = this.getNextModuleEventLocation(
      event.moduleSerialNumber!,
      new Date(event.logDate),
    );

    if (
      location &&
      (event.payload.toFuncLocationId !== location?.funcLocationId ||
        event.payload.toEmplacementId !== location?.emplacementId)
    ) {
      conflictMerge({ type: 'moveToLocation' }, event);
    }
  }

  private checkCounter(event: EventMergeRecord<CounterLog>) {
    if (!isCounterEvent(event)) return;

    const previousEvents = this.events
      .filter(
        (item) =>
          isCounterEvent(item) &&
          item.funcLocationId === event.funcLocationId &&
          isBefore(new Date(item.logDate), new Date(event.logDate)) &&
          !this.hasDeleteResolve(item.conflicts),
      )
      .sort((a, b) =>
        compareDesc(new Date(a.logDate), new Date(b.logDate)),
      ) as EventMergeRecord<CounterLog>[];

    for (const prev of previousEvents) {
      if (event.log.counter < prev.log.counter) {
        conflictMerge({ type: 'higherPreviousCounter', resolve: { type: 'delete' } }, event);
        return;
      }
    }
  }

  private checkMachineStatus(event: EventMergeRecord<MachineStatusLog>) {
    if (!isMachineStatusEvent(event)) return;

    const previousEvent = this.events
      .filter(
        (item) =>
          isMachineStatusEvent(item) &&
          item.funcLocationId === event.funcLocationId &&
          isBefore(new Date(item.logDate), new Date(event.logDate)) &&
          !this.hasDeleteResolve(item.conflicts),
      )
      .sort((a, b) => compareDesc(new Date(a.logDate), new Date(b.logDate)))[0] as
      | EventMergeRecord<MachineStatusLog>
      | undefined;

    if (previousEvent?.log.status === event.log.status) {
      conflictMerge(
        { type: 'duplicate', duplicatesEventsIds: [previousEvent.id], resolve: { type: 'delete' } },
        event,
      );
    }
  }

  private prepareRecords(records: EventMergeRecord[]) {
    return [...records]
      .sort((a, b) => compareAsc(new Date(a.logDate), new Date(b.logDate)))
      .map((record) => this.applyPatch(record));
  }

  private applyPatch(record: EventMergeRecord) {
    const clone = structuredClone(record);
    if (clone.conflicts) {
      for (const conflict of clone.conflicts) {
        if (conflict?.resolve?.patch) {
          for (const key in conflict.resolve.patch) {
            set(clone, key, conflict.resolve.patch[key]);
          }
        }
      }
    }
    return clone;
  }

  public getModuleLocationAt(
    moduleSerialNumber: string,
    at: Date,
  ): EventMergeModuleLocation | undefined {
    const [event] = this.events
      .filter(
        (event) =>
          isBefore(new Date(event.logDate), at) &&
          event.type === 'move' &&
          event.moduleSerialNumber === moduleSerialNumber &&
          !this.hasDeleteResolve(event.conflicts),
      )
      .sort((a, b) => compareDesc(new Date(a.logDate), new Date(b.logDate)));

    if (event) {
      if (!isMoveEvent(event)) return;
      return isMoveEvent(event) && event?.payload.toFuncLocationId
        ? { funcLocationId: event.payload.toFuncLocationId! }
        : { emplacementId: event.payload.toEmplacementId! };
    }
  }

  private hasDeleteResolve(conflicts?: EventMergeConflict[]) {
    if (!conflicts) return false;
    for (const conflict of conflicts) {
      if (conflict.resolve?.type === 'delete') return true;
    }
    return false;
  }

  private getNextModuleEventLocation(
    moduleSerialNumber: string,
    at: Date,
  ): EventMergeModuleLocation | undefined {
    const results = this.events
      .filter(
        (event) =>
          isAfter(new Date(event.logDate), at) &&
          event.moduleSerialNumber === moduleSerialNumber &&
          !this.hasDeleteResolve(event.conflicts),
      )
      .sort((a, b) => compareAsc(new Date(a.logDate), new Date(b.logDate)));

    if (results[0]) {
      const event = results[0];
      if (isMaintenanceEvent(event)) {
        return {
          funcLocationId: event.funcLocationId,
          emplacementId: event.emplacementId,
        } as EventMergeModuleLocation;
      } else if (isMoveLog(event.log)) {
        return {
          funcLocationId: event.log.from.funcLocationId,
          emplacementId: event.log.from.emplacementId,
        } as EventMergeModuleLocation;
      }
    }
  }

  private getFuncLocationModuleAt(funcLocationId: string, at: Date): string | undefined {
    const results = this.events
      .filter(
        (event) =>
          isBefore(new Date(event.logDate), at) &&
          !this.hasDeleteResolve(event.conflicts) &&
          (event.funcLocationId === funcLocationId ||
            (isMoveEvent(event) && event.payload.toFuncLocationId === funcLocationId)),
      )
      .sort((a, b) => compareDesc(new Date(a.logDate), new Date(b.logDate)));

    return results[0]?.moduleSerialNumber || undefined;
  }

  private hasPossibleDuplicates(event: EventMergeRecord): string[] | undefined {
    if (isMaintenanceEvent(event) && !!event.taskId) {
      return this.events
        .filter(
          (item) =>
            item.type === event.type &&
            item.id !== event.id &&
            item.taskId === event.taskId &&
            item.moduleSerialNumber === event.moduleSerialNumber &&
            item.funcLocationId === event.funcLocationId &&
            !this.hasDeleteResolve(item.conflicts),
        )
        .map((record) => record.id);
    } else if (isMoveEvent(event)) {
      return this.events
        .filter(
          (item) =>
            isMoveEvent(item) &&
            !this.hasDeleteResolve(item.conflicts) &&
            item.id !== event.id &&
            item.moduleSerialNumber === event.moduleSerialNumber &&
            item.payload.toFuncLocationId === event.payload.toFuncLocationId &&
            item.payload.toEmplacementId === event.payload.toEmplacementId &&
            (event.log as MoveModuleLog).from.funcLocationId ===
              (item.log as MoveModuleLog).from.funcLocationId &&
            (event.log as MoveModuleLog).from.emplacementId ===
              (item.log as MoveModuleLog).from.emplacementId,
        )
        .map((record) => record.id);
    }
  }
}

function isMoveEvent(event: MutationEvent): event is MutationEventMoveModule {
  return event.type === 'move';
}

function isMaintenanceEvent(event: MutationEvent): event is MutationEventMaintenance {
  return event.type === 'maintenance';
}

function isCounterEvent(event: MutationEvent): event is MutationEventMaintenance {
  return event.type === 'counter';
}

function isMachineStatusEvent(event: MutationEvent): event is MutationEventMaintenance {
  return event.type === 'machineStatus';
}

function isMoveLog(log: MutationLog): log is MoveModuleLog {
  return !!((log as MoveModuleLog).to || (log as MoveModuleLog).from);
}

function conflictMerge(conflict: EventMergeConflict, event: EventMergeRecord) {
  const index = event.conflicts!.findIndex((item) => item.type === conflict.type);
  if (index >= 0) {
    event.conflicts![index] = {
      ...conflict,
      ...event.conflicts![index],
    };
  } else {
    event.conflicts!.push(conflict);
  }
}

function removeDuplicateConflict(event: EventMergeRecord) {
  if (event.type === 'machineStatus') return;

  const index = event.conflicts?.findIndex((item) => item.type === 'duplicate');
  if (index !== undefined && index >= 0) {
    event.conflicts?.splice(index, 1);
  }
}

function eventIsDeleted(event: EventMergeRecord) {
  if (event.conflicts) {
    for (const conflict of event.conflicts) {
      if (conflict.resolve?.type === 'delete') return true;
    }
  }
  return false;
}

export function cleanEventMergeRecord(event: EventMergeRecord): MutationEvent {
  const clone = structuredClone(event);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (clone as any).log;
  delete clone.conflicts;
  return clone;
}

export async function cleanDeletedEvents(events: EventMergeRecord[]) {
  for (const event of events) {
    if (eventIsDeleted(event)) {
      await idb.mutationsEvents.delete(event.id);
      switch (event.type) {
        case 'maintenance':
          const maintenanceLog = await idb.maintenances.get(event.id);
          if (maintenanceLog) {
            const { pictures, documents } = maintenanceLog;
            for (const file of [...pictures, ...documents]) {
              await idb.files.delete(file.uri);
            }
          }

          const stocksLogs = (await idb.stocksLogs.toArray()).filter(
            (item) => item.maintenanceLogEventId === maintenanceLog?.eventId,
          );
          for (const stockLog of stocksLogs) {
            await removeStockLog(stockLog);
          }
          await idb.maintenances.delete(event.id);
          break;
        case 'move':
          const moveLog = await idb.moves.get(event.id);
          if (moveLog) await removeMoveLog(moveLog);
          break;
        case 'stock':
          const stockLog = await idb.stocksLogs.get(event.id);
          if (stockLog) await removeStockLog(stockLog);
          break;
        case 'counter':
          await idb.countersLogs.delete(event.id);
          break;
        case 'resetOverdue':
          await idb.resetOverdueLogs.delete(event.id);
          break;
        case 'machineStatus':
          await idb.machineStatusLogs.delete(event.id);
          break;
      }
    }
  }
  return events.filter((event) => !eventIsDeleted(event));
}

async function removeStockLog(stockLog: StockLog) {
  const stock = await idb.stocks.get({
    emplacementId: stockLog.emplacementId,
    amosId: stockLog.amosId,
  });
  if (!stock) return;

  stock.quantity = (stock.quantity || 0) - stockLog.quantity;
  await idb.stocks.put(stock);
  await idb.stocksLogs.delete(stockLog.eventId);
}

async function removeMoveLog(moveLog: MoveModuleLog) {
  const lastMoveLog = await getLastMoveLog(moveLog.moduleSerialNumber);
  if (lastMoveLog.eventId === moveLog.eventId) {
    const module = await idb.modules.get(moveLog.moduleSerialNumber!);
    if (!module) throw new Error('Module not found');

    module.funcLocationId = moveLog.from.funcLocationId;
    module.emplacementId = moveLog.from.emplacementId;
    await idb.modules.put(module);
  }
  await idb.moves.delete(moveLog.eventId);
}
